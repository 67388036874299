import { FormEvent, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CheckboxSwitch } from 'src/components/Buttons/CheckboxSwitch';
import { ModalPopup } from 'src/components/Modal/ModalPopup';
import { useAppContext } from 'src/hooks/useAppContext';
import { ExpenseSheetType, ExpenseWorkflowHistoryItemType } from 'src/types/types_expensesheet';
import { UserSimpleUidType } from 'src/types/types_user';
import { getTodayIso } from 'src/util/datetools';
import { serverTimestampAsDate } from 'src/util/util_firestoredates';
import { log_db_write, log_possible_bug } from 'src/util/util_log';
import { AutosaveSheetType } from './ExpenseSheet';
import { getWorkflowHistoryMaxId } from './util_expensesheetobject';
import { send_expense_sheet_email } from './util_sendemail';



type ModalActionType = {
  action: 'save';
  cbSavesheet: (comment: string, sendEmail: boolean) => void;
  emailAddresses: string[];
};

interface ExpenseSubmitButtonProps {
  sheet: ExpenseSheetType;
  label: string;
  isNeedValidation: boolean;
  isValidated?: boolean;
  validationMessageText?: string;
  newStatus: string;
  styleColor: string;
  autosaveNewStep: AutosaveSheetType;
  arrows?: 'previous' | 'previous x2' | 'next';
  closeSheet?: boolean;
  setShowModalRedirect: (value: boolean) => void;
  confirmGuideOverride?: boolean;
}

export function ExpenseSubmitButton({
  sheet,
  label,
  isNeedValidation,
  isValidated,
  validationMessageText,
  newStatus,
  styleColor,
  autosaveNewStep,
  arrows,
  closeSheet,
  setShowModalRedirect,
  confirmGuideOverride,
}: ExpenseSubmitButtonProps) {

  const { db, userDetails } = useAppContext();

  const [inProgress, setInProgress] = useState(false);

  const [modalAction, setModalAction] = useState<ModalActionType | null>(null);


  const location = useLocation();

  const userSimple = useMemo(() => {
    return {
      uid: userDetails.id,
      email: userDetails.email,
      name: userDetails.displayNameEn,
    } as UserSimpleUidType;
  }, [userDetails]);


  return (
    <>



      <ModalPopup
        title={label}
        okLabel='OK'
        show={!!modalAction}
        callbackClose={() => setModalAction(null)}
        onSubmit={(e: FormEvent<HTMLFormElement>, onSuccess) => {
          e.preventDefault();

          if (!modalAction)
            throw new Error('modalAction is null');

          const comment: string = (e.target as any).workflowcomment.value.trim();
          const notifyByEmail: boolean = (e.target as any).notifyByEmail.checked;

          if (modalAction.action === 'save') {

            const cbSavesheet = modalAction.cbSavesheet;
            cbSavesheet(comment, notifyByEmail);

          } else {
            throw new Error(`unknown modal action ${modalAction.action}`);
          }

        }}
        body={
          (modalAction && modalAction.action === 'save') ? (
            <div>
              <div className='mb-2'>
                Comment (optional):
              </div>
              <div className=''>
                <input type='text' className='form-control' id='workflowcomment' />
              </div>
              <div className='mt-3'>
                <CheckboxSwitch id='notifyByEmail' label={
                  <>
                    Notify by email:<br />{modalAction.emailAddresses.join(' ')}
                  </>
                } />
              </div>
            </div>
          ) : ''
        } />



      <button className={`btn btn-outline-${styleColor} m-3`} onClick={(e) => {

        if (confirmGuideOverride && !window.confirm('Guide override should be used only when guide is unable to submit the sheet themselves (e.g. left the company).\n\nContinue?')) {
          return;
        }

        if (isNeedValidation && !isValidated) {
          console.log('validationMessageText', validationMessageText);
          alert(`Please fix the below issues before submitting:\n\n${validationMessageText}`);
          return;
        }

        const cbSavesheet = (comment: string, sendEmail: boolean) => {

          setInProgress(true);

          // check just in case
          // TODO: do this check on all documents, in a script
          const lastWfStatus = sheet.workflowHistory[getWorkflowHistoryMaxId(sheet)].status;
          if (lastWfStatus !== sheet.status) {
            log_possible_bug({ db, userDetails, logkey: 'possible_bug.expense_sheet.status_mismatch', desc: `Saving expense sheet [${sheet.id}] but status [${sheet.status}] doesn't match last WF status [${lastWfStatus}]` });
          }


          log_db_write({ db, userDetails, logkey: 'db_write.expense_sheet.save', desc: `Save expense sheet [${sheet.tourCode}] [${sheet.id}]` });

          const maxId = getWorkflowHistoryMaxId(sheet);
          const newId = maxId + 1;
          const newWorkflowStep: ExpenseWorkflowHistoryItemType = {
            id: newId,
            date: serverTimestampAsDate(),
            status: newStatus,
            comment: comment,
            userId: userDetails.id,
            userEmail: userDetails.email,
            userName: userDetails.displayNameEn,
            dismissedUserIds: {},
          };

          const updateObj: Partial<ExpenseSheetType> = {
            status: newStatus,
            [`workflowHistory.${newId}`]: newWorkflowStep,
          };

          if (newStatus === 'SUBMITTED') {
            // record submission date as accounting date (not approval date as that's outside of guide's control)
            updateObj.accountingDateiso = getTodayIso();
            updateObj.accountingDateSet = serverTimestampAsDate();
          }

          autosaveNewStep(`WORKFLOW: ${newStatus}`, updateObj, 'UNDOWALL') // NOT undoable
            .then(() => {

              if (sendEmail) {
                send_expense_sheet_email(comment, sheet, userDetails, db);
              }

              setInProgress(false);

              if (closeSheet) {
                setShowModalRedirect(true);
              }
            })
            .catch((err) => {
              console.error('Saving new status FAILED', err);
              window.alert(err);
            });
        };

        setModalAction({
          action: 'save',
          cbSavesheet,
          emailAddresses: [...sheet.usersDesigners.map((user) => user.email), sheet.userGuideEmail],
        });

      }}>
        {inProgress
          ? `${isNeedValidation ? 'Submitting...' : 'Saving...'}`
          : (
            <>
              {arrows === 'previous' && (
                <i className='bi bi-chevron-double-left me-2'></i>
              )}
              {arrows === 'previous x2' && (
                <>
                  <i className='bi bi-chevron-double-left me-0'></i>
                  <i className='bi bi-chevron-double-left me-2'></i>
                </>
              )}
              {label}
              {arrows === 'next' && (
                <i className='bi bi-chevron-double-right ms-2'></i>
              )}
            </>
          )}
      </button>

    </>

  );
}
