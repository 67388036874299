import { ExpenseSheetType } from 'src/types/types_expensesheet';
import { formatNum } from 'src/util/util_formatnum';


interface C_CalculationSummaryProps {
  sheet: ExpenseSheetType;
}

export function C_CalculationSummary({
  sheet,
}: C_CalculationSummaryProps) {
  return (
    <>

      <h5 className=''>Summary</h5>

      <div className='expense-summary'>
        <div className='row px-2 py-1'>
          <div className='col col-lg-2 pt-2'><label htmlFor='summaryTransportation'>Transportation</label></div>
          <div className='col col-lg-1 pt-2 text-end'>+</div>
          <div className='col col-lg-2'><input className='form-control' id='summaryTransportation' readOnly value={formatNum(sheet.calc.totalTLTransportation)} /></div>
          <div className='col col-lg-1 pt-2'>JPY</div>
        </div>
        <div className='row px-2 py-1'>
          <div className='col col-lg-2 pt-2'><label htmlFor='summaryOtherExpenses'>Other expenses</label></div>
          <div className='col col-lg-1 pt-2 text-end'>+</div>
          <div className='col col-lg-2'><input className='form-control' id='summaryOtherExpenses' readOnly value={formatNum(sheet.calc.totalOtherExpenses)} /></div>
          <div className='col col-lg-1 pt-2'>JPY</div>
        </div>
        <div className='row px-2 pt-1 pb-2'>
          <div className='col col-lg-2 pt-2'><label htmlFor='summaryMealAllowance'>Meal allowance</label></div>
          <div className='col col-lg-1 pt-2 text-end'>+</div>
          <div className='col col-lg-2'><input className='form-control' id='summaryMealAllowance' readOnly value={formatNum(sheet.calc.totalMealAllowance)} /></div>
          <div className='col col-lg-1 pt-2'>JPY</div>
        </div>
        <div className='row px-2 py-0'>
          <div className='col-lg-6' style={{ borderTop: '1px solid #666' }}></div>
        </div>
        <div className='row px-2 pt-2 pb-1'>
          <div className='col col-lg-2 pt-2'><label htmlFor='summaryTotalExpenses'>Total expenses</label></div>
          <div className='col col-lg-1 pt-2 text-end'></div>
          <div className='col col-lg-2'><input className='form-control' id='summaryTotalExpenses' readOnly value={formatNum(sheet.calc.totalExpenses)} /></div>
          <div className='col col-lg-1 pt-2'>JPY</div>
        </div>
        <div className='row px-2 py-1'>
          <div className='col col-lg-2 pt-2'><label htmlFor='summaryAdvancePayment'>Already paid</label></div>
          <div className='col col-lg-1 pt-2 text-end'>&#x2212;</div>
          <div className='col col-lg-2'><input className='form-control' id='summaryAdvancePayment' readOnly value={formatNum(sheet.calc.totalAdvancePaid)} /></div>
          <div className='col col-lg-1 pt-2'>JPY</div>
        </div>
        <div className='row px-2 py-0'>
          <div className='col-lg-6' style={{ borderTop: '1px solid #666' }}></div>
        </div>
        <div className='row px-2 pt-2 pb-1'>
          <div className='col col-lg-2 pt-2'><label htmlFor='summaryBalance'>Balance due</label></div>
          <div className='col col-lg-1 pt-2 text-end'></div>
          <div className='col col-lg-2'><input className='form-control' id='summaryBalance' readOnly value={formatNum(sheet.calc.balance)} /></div>
          <div className='col col-lg-1 pt-2'>JPY</div>
        </div>
      </div>
    </>
  );
}
