import { doc, DocumentSnapshot, onSnapshot, updateDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ButtonTW } from 'src/components/Buttons/ButtonTW';
import { EditableFieldWithBorder } from 'src/components/EditableField/EditableFieldWithBorder';
import { getLoadingSpinnerOrNull } from 'src/components/Spinner/util_getLoadingSpinnerOrNull';
import { useAppContext } from 'src/hooks/useAppContext';
import { AgencySimpleType, AgencyType } from 'src/types/types_agencies';
import { log_db_write } from 'src/util/util_log';
import { deleteAgency } from '../util_agencies';



export function PageAgencyCrud() {

  const { db, setDbError, storage, userDetails, _lang } = useAppContext();
  const { agencyId } = useParams();

  const navigate = useNavigate();

  const [editedCell, setEditedCell] = useState<string | null>(null);

  const [agency, setAgency] = useState<AgencyType>();
  useEffect(() => {

    if (!agencyId) {
      setAgency(undefined);
      return;
    }

    const processSnapshot = function (docu: DocumentSnapshot) {
      const agency = { ...docu.data(), id: docu.id } as AgencyType;
      // verifyNotDeleted(docu.exists(), agency, agencyId, setDbError, 'agency') // we don't call verifyNotDeleted because it causes an error when the user is deleting an agency
      if (!docu.exists() || agency._isDeleted) {
        setAgency(undefined);
        return;
      }
      // convertAgencyDates(agency)
      console.log('agency', agency);
      setAgency(agency);

      // if (agency.history?.currentStepId) {
      //   addToCache(agency.history.currentStepId, agency)
      // }
    };

    const q = doc(db, 'agencies', agencyId);
    const unsubscribe = onSnapshot(q, processSnapshot, (err) => setDbError(`Getting agency ${agencyId}`, err));
    return unsubscribe;
  }, [db, setDbError, agencyId]);



  const [filterCacheAgencies, setFilterCacheAgencies] = useState<AgencySimpleType[]>();
  useEffect(() => {
    const processSnapshot = function (snapshot: DocumentSnapshot) {
      const docu = snapshot.data()!;
      const objlist = docu.objlist as AgencySimpleType[];
      setFilterCacheAgencies(objlist);
    };

    const unsubscribe = onSnapshot(doc(db, '_cachedlists', 'cachedlistAgencies'), processSnapshot,
      (err) => setDbError('Getting _cachedlists/cachedlistAgencies', err)
    );

    return unsubscribe;
  }, [db, setDbError]);



  const loadingSpinner = getLoadingSpinnerOrNull([
    ['agency list', filterCacheAgencies],
    // ['agency', agency],
  ]);
  if (
    // !agency ||
    !filterCacheAgencies)
    return loadingSpinner;

  const simpleAgencyList = filterCacheAgencies;

  const numRequests = agency?.requestMap ? Object.keys(agency.requestMap).length : 0;

  return (
    <div className='tw-container mx-auto px-4 py-4'>
      <Helmet>
        <title>{agency ? `Edit Agency: ${agency.name}` : 'Edit Agency'}</title>
      </Helmet>

      <h1>Edit Agency/Platform name</h1>

      {/* <div className='tw-flex tw-items-center tw-gap-8 tw-py-8'> */}
      <div className='tw-grid tw-grid-cols-[20em,30em] tw-items-center tw-gap-4 tw-w-fit
      tw-border tw-border-solid tw-border-slate-800/20 tw-rounded tw-px-4 tw-py-2 tw-my-8'>
        <div>
          Search for agency:
        </div>

        <Typeahead
          className={'typeahead-suppress-popper-warning'}
          id='tourrequestAgencyOrPlatform'
          labelKey='name'
          options={simpleAgencyList}
          minLength={2}
          disabled={false}
          allowNew={true}
          selected={[]}
          filterBy={['name']}
          // @ts-expect-error Typeahead
          onChange={(array: (AgencySimpleType & { customOption: boolean })[]) => {
            if (array.length === 0) {
              return;
            }

            const value = array[0];

            if (value.customOption) {
              return;
            }

            if (value.name && value.id) {
              navigate(`/clients/edit/${value.id}`);
            }
          }}
        />
      </div>

      {agency && (
        <div className='tw-w-fit
      tw-border tw-border-solid tw-border-slate-800/20 tw-rounded tw-my-8'>

          <div className='tw-col-span-2 tw-bg-slate-500/20 tw-py-2 tw-px-4 tw-flex tw-justify-between'>
            <h5 className=' tw-m-0'>
              {agency.name}
            </h5>
            <div>
              <Link to='/clients/edit/'>close</Link>
            </div>
          </div>

          <div className='tw-grid tw-grid-cols-[20em,30em] tw-items-center tw-gap-4 tw-p-4'>
            <div>
              Rename:
            </div>

            <EditableFieldWithBorder
              tableid='agency'
              rowid='main'
              fieldname='name'
              validationType=''
              currentValue={agency.name}
              isClickableToEdit={true}
              editedCell={editedCell}
              setEditedCell={setEditedCell}
              callbackCommitChange={(value) => {
                const newName = value.trim();

                if (!window.confirm(`Rename agency\n   ${agency.name}\nto\n   ${newName}\n?`)) {
                  setEditedCell(null);
                  return;
                }

                const updateObj: Partial<AgencyType> = {
                  name: newName,
                };

                if (!agencyId)
                  throw new Error('agencyId not set');

                log_db_write({ db, userDetails, logkey: 'db_write.agency.rename', desc: `Agency ${agencyId} renamed from ${agency.name} to ${newName}` });

                updateDoc(doc(db, 'agencies', agencyId), updateObj);

                setEditedCell(null);
              }}
            />

            <div>Two character code:</div>
            <div>{agency.twoCharacterCode}</div>

            <div>
              Number of requests:
            </div>

            <div className='tw-flex tw-gap-4'>
              <div>
                {numRequests}
              </div>
              <div>
                <Link to={`/requests/list/?agencyId=${agency.id}`}>View list</Link>
              </div>
            </div>

            <div className='tw-self-start'>
              Notes:
            </div>

            <div>
              <EditableFieldWithBorder
                tableid='agency'
                rowid='main'
                fieldname='notes'
                validationType=''
                currentValue={agency.notes ?? ''}
                isClickableToEdit={true}
                editedCell={editedCell}
                setEditedCell={setEditedCell}
                callbackCommitChange={(value) => {
                  const newNotes = value.trim();

                  const updateObj: Partial<AgencyType> = {
                    notes: newNotes,
                  };

                  if (!agencyId)
                    throw new Error('agencyId not set');

                  log_db_write({ db, userDetails, logkey: 'db_write.agency.edit.notes', desc: `Agency ${agencyId} edit notes to ${newNotes}` });

                  updateDoc(doc(db, 'agencies', agencyId), updateObj);

                  setEditedCell(null);
                }}
                isTextArea={true}
                textareaRows={4}
              />
            </div>

            <div>
              Delete
            </div>

            <div>
              <ButtonTW variant={numRequests ? 'bsDarkGray' : 'blue'} onClick={(e) => {
                deleteAgency(agency, userDetails, db)
                  .then((result) => {
                    if (!result) return;
                    alert('Agency deleted');
                    navigate('/clients/edit/');
                  })
                  .catch((err) => setDbError(`Deleting agency ${agency.id}`, err));
              }}>Delete this agency/platform</ButtonTW>
            </div>

          </div>
        </div>
      )}


    </div>
  );
}
