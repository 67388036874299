import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { H_ExpenseRules } from 'src/pages/ExpenseSheet/Sections/H_ExpenseRules';


export function ExpenseRulesPage() {


  return (
    <div className='container'>
      <Helmet><title>Expense rules</title></Helmet>
      <div className='my-3'>
        <Link to='/guide/expenses/'>&lt;&lt;Back to expense sheet list</Link>
      </div>

      <H_ExpenseRules />

    </div>
  );
}
