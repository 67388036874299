import { addDoc, collection, doc, getDoc } from 'firebase/firestore';
import { useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ButtonTW } from 'src/components/Buttons/ButtonTW';
import { CheckboxSwitch } from 'src/components/Buttons/CheckboxSwitch';
import { TypeaheadUserList } from 'src/components/FormControls/TypeaheadUserList';
import { ModalPopup } from 'src/components/Modal/ModalPopup';
import { getNewHistoryInfoObj } from 'src/hooks/autosave/util_undoredo';
import { useAppContext } from 'src/hooks/useAppContext';
import 'src/pages/ExpenseSheet/expenses.css';
import { calculateAmounts } from 'src/pages/ExpenseSheet/util_calcamounts';
import { addMetadataModifiedExpenseSheet } from 'src/pages/ExpenseSheet/util_db_expensesheet';
import { AdvancePaymentType, ExpenseSheetType } from 'src/types/types_expensesheet';
import { UserSimpleTeamType, UserSimpleType, UserSimpleUidType } from 'src/types/types_user';
import { serverTimestampAsDate } from 'src/util/util_firestoredates';
import { nano_id } from 'src/util/util_nano_id';
import { ExpenseListModalActionType, ExpenseListTable } from './ExpenseListTable';
import './expenselist.css';



interface ExpenseListAllGuidesProps {
  sheetListAll: ExpenseSheetType[];
  userListSimple: UserSimpleTeamType[];
}

export function ExpenseListAllGuides({
  sheetListAll,
  userListSimple,
}: ExpenseListAllGuidesProps) {


  // user is definitely travel designer

  const { db, userDetails, setDbError } = useAppContext();

  const [newSheetSelectedGuide, setNewSheetSelectedGuide] = useState<UserSimpleType[]>([]);

  const [showApprovedSheets, setShowApprovedSheets] = useState(false);
  const [filterByGuide, setFilterByGuide] = useState<string | null>(null);
  const [filterByDesigner, setFilterByDesigner] = useState<string | null>(null);

  const navigate = useNavigate();

  const [modalAction, setModalAction] = useState<ExpenseListModalActionType | null>(null);

  const userSimple = useMemo(() => {
    return {
      uid: userDetails.id,
      email: userDetails.email,
      name: userDetails.displayNameEn,
    } as UserSimpleUidType;
  }, [userDetails]);



  const sheetShouldBeShown = (
    sheet: ExpenseSheetType,
    ignoreGuideFilter = false,
    ignoreDesignerFilter = false
  ) => {
    return (showApprovedSheets || sheet.status !== 'APPROVED')
      && (ignoreGuideFilter || !filterByGuide || sheet.userGuideUid === filterByGuide)
      && (ignoreDesignerFilter || !filterByDesigner || sheet.usersDesigners.find((user) => user.uid === filterByDesigner));
  };

  const sheetListAllFiltered = sheetListAll.filter((sheet) => sheetShouldBeShown(sheet));


  const guideList: UserSimpleUidType[] = [];
  const numSheetsPerGuide = new Map<string, number>();
  numSheetsPerGuide.set('ALL', 0);

  const travelDesignerList: UserSimpleUidType[] = [];
  const numSheetsPerDesigner = new Map<string, number>();
  numSheetsPerDesigner.set('ALL', 0);

  for (const sheet of sheetListAll) {
    if (!guideList.find((guide) => guide.uid === sheet.userGuideUid)) {
      guideList.push({
        uid: sheet.userGuideUid,
        email: sheet.userGuideEmail,
        name: sheet.userGuideName,
      });
    }
    if (!numSheetsPerGuide.has(sheet.userGuideUid)) {
      numSheetsPerGuide.set(sheet.userGuideUid, 0);
    }
    if (sheetShouldBeShown(sheet, true, false)) {
      numSheetsPerGuide.set(sheet.userGuideUid, numSheetsPerGuide.get(sheet.userGuideUid)! + 1);
      numSheetsPerGuide.set('ALL', numSheetsPerGuide.get('ALL')! + 1);
    }

    for (const designer of sheet.usersDesigners) {
      if (!travelDesignerList.find((td) => td.uid === designer.uid)) {
        travelDesignerList.push({
          uid: designer.uid,
          email: designer.email,
          name: designer.name,
        });
      }
      if (!numSheetsPerDesigner.has(designer.uid)) {
        numSheetsPerDesigner.set(designer.uid, 0);
      }
      if (sheetShouldBeShown(sheet, false, true)) {
        numSheetsPerDesigner.set(designer.uid, numSheetsPerDesigner.get(designer.uid)! + 1);
      }
    }

    if (sheetShouldBeShown(sheet, false, true)) {
      numSheetsPerDesigner.set('ALL', numSheetsPerDesigner.get('ALL')! + 1);
    }
  }

  guideList.sort((a, b) => a.name.localeCompare(b.name));
  travelDesignerList.sort((a, b) => a.name.localeCompare(b.name));

  /* grid layout: 2x2
           |
   (empty) |  Title, 'create new' button
  ---------+----------------------------
   guide   |
   list    |   Main table
           |
   designer|
   list    |
  */

  return (
    <div style={{
      display: 'grid',
      gridTemplateColumns: '14rem 1fr',
      gap: '1em',
    }}>

      <div style={{
        gridRow: 2,
        gridColumn: 1,
      }}>

        <div>
          <div style={{
            fontWeight: 'bold',
            cursor: 'pointer',
          }}
            className={filterByGuide === null ? 'selected' : undefined}
            onClick={() => {
              setFilterByGuide(null);
            }}>
            All tour leaders
            {' '}
            {(numSheetsPerGuide.get('ALL')! > 0) && (
              <span className='numOpenSheets numSheetsHighlight'>{numSheetsPerGuide.get('ALL')}</span>
            )}
          </div>
          <ul className='filterList'>
            {guideList.map((guide) => {
              return (
                <li key={guide.uid} className={filterByGuide === guide.uid ? 'selected' : undefined} onClick={() => {
                  setFilterByGuide(guide.uid);
                }}>
                  <i className='bi bi-chevron-right'></i>
                  {guide.name}
                  {' '}
                  {(numSheetsPerGuide.get(guide.uid)! > 0) && (
                    <span className='numOpenSheets numSheetsHighlight'>{numSheetsPerGuide.get(guide.uid)}</span>
                  )}
                </li>
              );
            })}
          </ul>
        </div>

        <div>
          <div style={{
            fontWeight: 'bold',
            cursor: 'pointer',
          }}
            className={filterByDesigner === null ? 'selected' : undefined}
            onClick={() => {
              setFilterByDesigner(null);
            }}>
            All designers
            {' '}
            {(numSheetsPerDesigner.get('ALL')! > 0) && (
              <span className='numOpenSheets numSheetsHighlight'>{numSheetsPerDesigner.get('ALL')}</span>
            )}
          </div>
          <ul className='filterList'>
            {travelDesignerList.map((designer) => {
              return (
                <li key={designer.uid} className={filterByDesigner === designer.uid ? 'selected' : undefined} onClick={() => {
                  setFilterByDesigner(designer.uid);
                }}>
                  <i className='bi bi-chevron-right'></i>
                  {designer.name}
                  {' '}
                  {(numSheetsPerDesigner.get(designer.uid)! > 0) && (
                    <span className='numOpenSheets numSheetsHighlight'>{numSheetsPerDesigner.get(designer.uid)}</span>
                  )}
                </li>
              );
            })}
          </ul>
        </div>

      </div>

      <div style={{
        gridRow: 1,
        gridColumn: 2,
      }}>


        <h3 className='mt-4 mb-2'>Expense sheet list (all tour leaders)</h3>

        <div className='mt-2 mb-4'>
          <Link to='/guide/expenses/rules'>View expense rules</Link>
        </div>

        <ButtonTW variant='blue' textSize='md' className='mb-3' onClick={(e) => {
          setModalAction({
            action: 'create new sheet',
          });
        }}>
          Create new expense sheet
        </ButtonTW>

        <div className='mb-3'>
          <CheckboxSwitch
            id='chkShowApprovedSheets'
            label='Show Approved sheets'
            checked={showApprovedSheets}
            onChange={(e) => setShowApprovedSheets(e.target.checked)}
          />
        </div>

      </div>

      <div style={{
        gridRow: 2,
        gridColumn: 2,
      }}>



        <ExpenseListTable
          sheetList={sheetListAllFiltered}
          isTravelDesignerTable={true}
          setModalAction={setModalAction}
        />


        <ModalPopup
          title={modalAction && modalAction.action === 'duplicate sheet' ? 'Duplicate expense sheet' : 'Create new expense sheet'}
          okLabel='Create'
          show={!!modalAction}
          callbackClose={() => setModalAction(null)}
          onSubmit={(e, onSuccess) => {
            e.preventDefault();

            if (!modalAction)
              throw new Error('modalAction is null');

            console.log('newSheetSelectedGuide', newSheetSelectedGuide);

            if (!newSheetSelectedGuide || newSheetSelectedGuide.length !== 1) {
              alert('Select a guide from the list');
              return;
            }

            const guideSimple = newSheetSelectedGuide[0];

            if (guideSimple.id === userDetails.id) {
              alert('Cannot select self as guide');
              return;
            }

            if (modalAction.action === 'duplicate sheet') {

              const sheetId = modalAction.sheetId;

              getDoc(doc(db, 'expensesheets', sheetId))
                .then((doc) => {
                  const dupeSheet: ExpenseSheetType = {
                    ...doc.data() as ExpenseSheetType,
                    userCreatorUid: userDetails.id,
                    userCreatorEmail: userDetails.email,
                    userCreatorName: userDetails.displayNameEn,
                    usersDesigners: [{
                      uid: userDetails.id,
                      email: userDetails.email,
                      name: userDetails.displayNameEn,
                    }],
                    userGuideUid: guideSimple.id,
                    userGuideEmail: guideSimple.email,
                    userGuideName: guideSimple.name,
                    userModifiedUid: userDetails.id,
                    userModifiedEmail: userDetails.email,
                    userModifiedName: userDetails.displayNameEn,
                    _isDeleted: false,
                    status: 'TD_DRAFT',
                    dateCreated: serverTimestampAsDate(),
                    dateModified: serverTimestampAsDate(),
                    workflowHistory: {
                      '1': {
                        id: 1,
                        date: serverTimestampAsDate(),
                        status: 'INITIAL',
                        comment: `Copied from ${sheetId}`,
                        userId: userDetails.id,
                        userEmail: userDetails.email,
                        userName: userDetails.displayNameEn,
                        dismissedUserIds: {},
                      },
                      '2': {
                        id: 1,
                        date: serverTimestampAsDate(),
                        status: 'TD_DRAFT',
                        comment: '',
                        userId: userDetails.id,
                        userEmail: userDetails.email,
                        userName: userDetails.displayNameEn,
                        dismissedUserIds: {},
                      },
                    },
                    history: getNewHistoryInfoObj(userSimple, `Duplicate expense sheet [${sheetId}]`),
                  };
                  // dupeSheet.sheetTitle += (dupeSheet.sheetTitle ?? '') + ' (Copy)'

                  // need to recreate ids for advance payments
                  dupeSheet.advancePayments.forEach((ap: AdvancePaymentType) => {
                    ap.id = nano_id();
                  });

                  addDoc(collection(db, 'expensesheets'), addMetadataModifiedExpenseSheet(dupeSheet, userDetails))
                    .then(() => {
                      // success
                      if (onSuccess) onSuccess();
                    });
                })
                .catch((err) => setDbError(`Getting expense sheet ${sheetId}`, err));

            } else if (modalAction.action === 'create new sheet') {

              const newSheet: Omit<ExpenseSheetType, 'id'> = {
                tourCode: '',
                paxName: '',
                sheetTitle: '',
                numberOfPax: '', // currently a string. TODO: change type to number
                dateisoTourStart: '',
                dateisoTourEnd: '',
                accountingDateiso: '',
                accountingDateSet: null,
                advancePayments: [],
                guideTransportation: [],
                otherExpenses: [],
                mealAllowance: [],
                mealAllowancePrices: {
                  // not changeable by user, but need to be saved in sheet to allow calculation
                  lunchPrice: 1500,
                  dinnerPrice: 2000,
                },
                workflowHistory: {
                  '1': {
                    id: 1,
                    date: serverTimestampAsDate(),
                    status: 'TD_DRAFT', // cannot be 'DELETED'
                    comment: '',
                    userId: userDetails.id,
                    userEmail: userDetails.email,
                    userName: userDetails.displayNameEn,
                    dismissedUserIds: {},
                  },
                },

                userCreatorUid: userDetails.id,
                userCreatorEmail: userDetails.email,
                userCreatorName: userDetails.displayNameEn,
                usersDesigners: [{
                  uid: userDetails.id,
                  email: userDetails.email,
                  name: userDetails.displayNameEn,
                }],
                userGuideUid: guideSimple.id,
                userGuideEmail: guideSimple.email,
                userGuideName: guideSimple.name,
                userModifiedUid: userDetails.id,
                userModifiedEmail: userDetails.email,
                userModifiedName: userDetails.displayNameEn,
                _isDeleted: false,
                status: 'TD_DRAFT',
                dateCreated: serverTimestampAsDate(),
                dateModified: serverTimestampAsDate(),
                history: getNewHistoryInfoObj(userSimple, 'Create new expense sheet'),

                calc: null!, // placeholder as we generate the calc object below
              };

              newSheet.calc = calculateAmounts({ ...newSheet, id: 'NEW_SHEET' }, new Map());

              addDoc(collection(db, 'expensesheets'), addMetadataModifiedExpenseSheet(newSheet, userDetails))
                .then((insertedSheet) => {

                  console.log('Newly created sheet id', insertedSheet.id);

                  navigate(`sheet/${insertedSheet.id}`);

                  // myModal.hide()
                  //if (onSuccess) onSuccess()
                });
            } else {
              // unreachable
              const _: never = modalAction;
              throw new Error(`unknown modal action ${(modalAction as ExpenseListModalActionType).action}`);
            }

          }}
          body={

            <div>
              <div className='mb-2'>
                Select guide:
              </div>
              <div className=''>
                <TypeaheadUserList
                  id='guide-typeahead'
                  userList={userListSimple}
                  multiple={false}
                  selected={newSheetSelectedGuide}
                  onChange={(array) => {
                    setNewSheetSelectedGuide(array);
                  }}
                  guidesFirst={true}
                />
              </div>
            </div>

          } />
      </div>
    </div>
  );
}
