import { useRef, useState } from 'react';
import { BarLoader } from 'react-spinners';
import { ButtonTW } from './components/Buttons/ButtonTW';
import { useAppContext } from './hooks/useAppContext';



export function HomePage() {

  const { userDetails, perm } = useAppContext();

  const iframeRef = useRef(null);
  const [iframeKey, setIframeKey] = useState(0);

  const notionUrl = 'https://e.notionhero.io/e1/p/73c015b-4d3fa1681731d2f1473038e1f427236';
  // const notionUrl = 'about:blank'


  const [iframeLoaded, setIframeLoaded] = useState(false);

  return (
    <div className='container'>

      {/* <Helmet bodyAttributes={{ style: 'background-color : #fff' }} /> */}


      {/*
      <div className="" style={{ padding: '5em', opacity: 1, margin: 'auto', width: 'fit-content' }}>
        <div style={{ width: 'fit-content', margin: 'auto' }} >
          <img src='/EightyDays_Logo_balloon_blue.svg' width="150" />
        </div>
        <div className="fst-italic my-5">
          Select an option from the menu at the top of the page
        </div>
      </div>
      */}

      {perm('view_notion') ? (
        <>
          <div>
            <ButtonTW variant='link' onClick={(e) => {
              // iframeRef.current.src = notionUrl
              setIframeLoaded(false);
              setIframeKey(iframeKey + 1);
            }}>Back to top</ButtonTW>
          </div>

          <div style={{ display: iframeLoaded ? 'none' : 'flex', justifyContent: 'center', position: 'absolute', left: '50%', marginLeft: '-50px', top: '14em' }}>
            <BarLoader color='#3b5a88' />
          </div>

          <div style={{ width: '100%', height: '80vh' }}>
            <iframe key={iframeKey} ref={iframeRef} width='100%' height='100%' src={notionUrl} onLoad={() => {
              setIframeLoaded(true);
            }}></iframe>
          </div>
        </>
      ) : (
        <div className='tw-my-10'>
          <h1 className='tw-text-3xl'>Welcome to Eighty Days Portal</h1>
          <p className='tw-my-4'>
            Select an option from the menu at the top of the page.
          </p>
        </div>
      )}
    </div>
  );
}
