import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ButtonTW } from 'src/components/Buttons/ButtonTW';



interface ModalRedirectToExpenseListProps {
  show: boolean;
  setShow: (value: boolean) => void;
}

export function ModalRedirectToExpenseList({
  show,
  setShow,
}: ModalRedirectToExpenseListProps) {

  const navigate = useNavigate();

  return (
    <Modal
      show={show}
    >

      <div style={{
        padding: '1em',
      }}>

        <div style={{
          display: 'flex',
          justifyContent: 'end',
        }}>
          <ButtonTW variant='none'>
            <i className='bi bi-x-lg' onClick={() => setShow(false)}></i>
          </ButtonTW>
        </div>

        <div style={{
          display: 'flex',
          padding: '2em',
          flexDirection: 'column',
          gap: '2em',
          alignItems: 'stretch',
        }}>

          <ButtonTW
            variant='blue'
            textSize='md'
            onClick={() => {
              navigate('/guide/expenses');
            }}>
            Return to Expense Sheet List
          </ButtonTW>

          <ButtonTW
            variant='darkgray_outline'
            textSize='md'
            onClick={() =>
              setShow(false)
            }>
            Stay on this page
          </ButtonTW>

        </div>

      </div>

    </Modal>

  );
}
