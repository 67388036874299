import { ChangeEvent } from 'react';
import { ModalPopup } from 'src/components/Modal/ModalPopup';
import { useAutosaveDocumentInList } from 'src/hooks/autosave/util_autosave';
import { useAppContext } from 'src/hooks/useAppContext';
import { InvoiceStatusType, InvoiceType } from 'src/types/types_invoices';
import { iso_from_local0, local0_from_iso } from 'src/util/datetools';
import { formatNum } from 'src/util/util_formatnum';
import { DateInput } from '../ExpenseSheet/DateInput';
import { EDPaymentSourceAccountDropdown } from './EDPaymentSourceAccountDropdown';
import { addMetadataModifiedInvoices, getDefaultPaymentDate } from './util_invoices';


export type ModalPopupMarkPaidActionType = {
  action: 'mark scheduled/paid' | null;
  isScheduling: boolean;
  isPaymentDateTouched?: boolean;
  paymentDateiso: string;
  paymentSourceAccount: string;
  invoice: InvoiceType;
};

interface ModalPopupMarkPaidProps {
  modalActionMarkPaid: ModalPopupMarkPaidActionType | null;
  setModalActionMarkPaid: (modalAction: ModalPopupMarkPaidActionType | null) => void;
  callbackOnSuccess: () => void;
}

export function ModalPopupMarkPaid({
  modalActionMarkPaid,
  setModalActionMarkPaid,
  callbackOnSuccess,
}: ModalPopupMarkPaidProps) {

  const { db, userDetails, setDbError } = useAppContext();

  const onChangeRadiobutton = modalActionMarkPaid ? ((e: ChangeEvent<HTMLInputElement>) => {
    // update scheduled/paid state
    const newModalActionMarkPaid: ModalPopupMarkPaidActionType = {
      ...modalActionMarkPaid,
      isScheduling: e.currentTarget.value === 'scheduled',
    };

    // update date if needed
    if (!modalActionMarkPaid.isPaymentDateTouched) {
      newModalActionMarkPaid.paymentDateiso = getDefaultPaymentDate(modalActionMarkPaid.isScheduling, modalActionMarkPaid.invoice);
    }
    setModalActionMarkPaid(newModalActionMarkPaid);
  }) : undefined;

  const autosaveNewStep = useAutosaveDocumentInList('invoices', addMetadataModifiedInvoices);

  return (
    <ModalPopup
      title={'Mark Invoice Paid'}
      okLabel='OK'
      show={!!modalActionMarkPaid}
      className='modal-lg'
      callbackClose={() => setModalActionMarkPaid(null)}
      body={modalActionMarkPaid && (

        <div className=''>
          <div className='row my-2'>
            <div className='col col-lg-4 pt-2'>Mark Scheduled or Paid:</div>
            <div className='col col-lg-8 my-2'>
              <div className='form-check'>
                <input className='form-check-input' type='radio' name='radioScheduledPaid' id='radioScheduledPaid_Scheduled' value='scheduled'
                  checked={modalActionMarkPaid.isScheduling}
                  onChange={onChangeRadiobutton} />
                <label className='form-check-label' htmlFor='radioScheduledPaid_Scheduled'>
                  Scheduled
                </label>
              </div>
              <div className='form-check'>
                <input className='form-check-input' type='radio' name='radioScheduledPaid' id='radioScheduledPaid_Paid' value='paid'
                  checked={!modalActionMarkPaid.isScheduling}
                  onChange={onChangeRadiobutton} />
                <label className='form-check-label' htmlFor='radioScheduledPaid_Paid'>
                  Paid
                </label>
              </div>
            </div>
          </div>
          <div className='row my-2'>
            <div className='col col-lg-4 pt-2'>Payment source account:</div>
            <div className='col col-lg-8'>
              <EDPaymentSourceAccountDropdown
                id='paymentSourceAccount'
                value={modalActionMarkPaid.paymentSourceAccount}
                onChange={(e) => {
                  setModalActionMarkPaid({
                    ...modalActionMarkPaid,
                    paymentSourceAccount: e.target.value,
                  });
                }} />
            </div>
          </div>
          <div className='row my-2'>
            <div className='col col-lg-4 pt-2'>Amount:</div>
            <div className='col col-lg-8'><input type='text' className='form-control' id='amount' disabled={true} defaultValue={formatNum(modalActionMarkPaid.invoice.amount)} /></div>
          </div>
          <div className='row my-2'>
            <div className='col col-lg-4 pt-2'>Payment date:</div>
            <div className='col col-lg-8'><DateInput value_local0={local0_from_iso(modalActionMarkPaid.paymentDateiso)} onChange={(date_local0) => {
              setModalActionMarkPaid({
                ...modalActionMarkPaid,
                paymentDateiso: date_local0 ? iso_from_local0(date_local0) : '',
              });
            }} /></div>
          </div>
          <div className='row my-2'>
            <div className='col col-lg-4 pt-2'>Payment to:</div>
            <div className='col col-lg-8'><input type='text' className='form-control' disabled={true} defaultValue={modalActionMarkPaid.invoice.payeeNameEn} /></div>
          </div>
          <div className='row my-2'>
            <div className='col col-lg-4 pt-2'>Tour code:</div>
            <div className='col col-lg-8'><input type='text' className='form-control' disabled={true} defaultValue={modalActionMarkPaid.invoice.tripcode} /></div>
          </div>
        </div>

      )}
      onSubmit={(e, onSuccess) => {
        e.preventDefault();

        if (!modalActionMarkPaid || !modalActionMarkPaid.action)
          throw new Error('modalAction is null');

        const invoice = modalActionMarkPaid.invoice;

        const scheduledPaid = (e.target as any).radioScheduledPaid.value;
        let newStatus: InvoiceStatusType;
        if (scheduledPaid === 'scheduled')
          newStatus = 'SCHEDULED';
        else if (scheduledPaid === 'paid')
          newStatus = 'PAID';
        else
          throw new Error('invalid scheduledPaid');


        if (!modalActionMarkPaid.paymentDateiso) {
          alert('Invalid payment date');
          return;
        }

        const paymentSourceAccount = modalActionMarkPaid.paymentSourceAccount;
        console.log(paymentSourceAccount);
        if (!paymentSourceAccount || paymentSourceAccount.startsWith('Select ')) {
          alert('Select payment source account from list');
          return;
        }

        const updateObj: Partial<InvoiceType> = {
          paymentDateiso: modalActionMarkPaid.paymentDateiso,
          paymentSourceAccount: paymentSourceAccount,
          paymentUserUid: userDetails.id,
          paymentUserEmail: userDetails.email,
          status: newStatus,
          statusPaidOrScheduled: true,
        };

        autosaveNewStep('Mark invoice PAID/SCHEDULED', invoice, updateObj, 'UNDOWALL') // status change -> not undoable
          .then(() => {
            setModalActionMarkPaid(null);
            callbackOnSuccess();
          })
          .catch((err) => setDbError(`Modal popup mark invoice paid ${invoice.id}`, err));


      }}
    />
  );
}
