import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { ButtonTW } from 'src/components/Buttons/ButtonTW';
import { CheckboxSwitch } from 'src/components/Buttons/CheckboxSwitch';
import { useSetOneSearchParam } from 'src/components/ColumnFilters/useSetOneSearchParam';
import { getTreeFilterString } from 'src/components/ColumnFilters/util_filters';
import { getLoadingSpinnerOrNull } from 'src/components/Spinner/util_getLoadingSpinnerOrNull';
import { useAppContext } from 'src/hooks/useAppContext';
import { userrole_isAdmin } from 'src/util/user_roles';
import { formatNum } from 'src/util/util_formatnum';
import { OneRequestTableType, PageTourRequestStateType } from '../types_tourrequest_state';
import { FilteringCriteriaPill } from './FilteringCriteriaPill';
import { RequestListTable } from './TableParts/RequestListTable';
import { RequestListTableRow } from './TableParts/RequestListTableRow';
import { StatusHeaderRow } from './TableParts/StatusHeaderRow';
import './tourrequestlist.css';



interface TourRequestListProps {
  state: PageTourRequestStateType;
}

export function TourRequestList({
  state,
}: TourRequestListProps) {

  const { db, setDbError, userDetails } = useAppContext();

  const [searchParams, setSearchParams] = useSearchParams();
  const { setOneSearchParam, getNewUrl } = useSetOneSearchParam('tourrequests');

  const location = useLocation();

  const {
    showAllColumns, setShowAllColumns,
    showSalesInfo1, setShowSalesInfo1,
    showExpensesMiniTable, setShowExpensesMiniTable,
    expandPaymentsTable, setExpandPaymentsTable,
    travellersShownReqIdList, setTravellersShownReqIdList,
    columnFilterAppliedData,
    columnFilterCacheData,
    travelDesignerList,
    disabledPagination,

    sortByStatus,
    requestTables,
    // requestList,
    // rowCount,
    // previousPagesLastDocs,
    // isLoading,
    // numberPerPage,
  } = state;


  const [editedCell, setEditedCell] = useState<string | null>(null);
  const [freeeUnlocked, setFreeeUnlocked] = useState(false);


  const [shownRequestAggregatorPopup, setShownRequestAggregatorPopup] = useState<string | null>(null);

  const [shownTourStatusPopup, setShownTourStatusPopup] = useState<string | null>(null);


  const getRows = (requestTable: OneRequestTableType) => {
    if (requestTable.queryErrorMessage) {
      return [
        <tr key='queryErrorMessage'>
          <td colSpan={100} className='tw-text-red-700'>{requestTable.queryErrorMessage}</td>
        </tr>,
      ];
    }
    if (!requestTable.requestList)
      return [];
    const indexFirstItem = requestTable.previousPagesLastDocs.length * requestTable.numberPerPage;
    const tablerows: JSX.Element[] = [];
    for (const [index, tourrequest] of requestTable.requestList.entries()) {
      tablerows.push(
        <RequestListTableRow
          key={`${requestTable.statusKey}_${tourrequest.id}`}
          tourrequest={tourrequest}
          statusKey={requestTable.statusKey}
          showAllColumns={showAllColumns}
          showSalesInfo1={showSalesInfo1}
          showPayments={true}
          showExpensesMiniTable={showExpensesMiniTable}
          expandPaymentsTable={expandPaymentsTable}
          setExpandPaymentsTable={setExpandPaymentsTable}
          indexFirstItem={indexFirstItem}
          index={index}
          travellersShownReqIdList={travellersShownReqIdList}
          setTravellersShownReqIdList={setTravellersShownReqIdList}
          shownRequestAggregatorPopup={shownRequestAggregatorPopup}
          setShownRequestAggregatorPopup={setShownRequestAggregatorPopup}
          shownTourStatusPopup={shownTourStatusPopup}
          setShownTourStatusPopup={setShownTourStatusPopup}
          columnFilterAppliedData={columnFilterAppliedData}
          editedCell={editedCell}
          setEditedCell={setEditedCell}
          freeeUnlocked={freeeUnlocked}
        />
      );
    }
    return tablerows;
  };



  // *** all hooks above this line ***


  const loadingSpinner = getLoadingSpinnerOrNull([
    ['list of requests', requestTables],
    ['list of users', travelDesignerList],
  ]);
  if (!requestTables || !travelDesignerList)
    return loadingSpinner;



  const tablerows: JSX.Element[] = [];
  let isLoading = false;


  // just some checks, maybe not needed
  if (!sortByStatus) {
    if (requestTables.length !== 1 || requestTables.map((rt) => rt.statusKey).join(',') !== 'all')
      throw new Error(`unexpected number of requestTables [${sortByStatus}] [${requestTables.length}]`);
  } else {
    if (requestTables.length !== 4 || requestTables.map((rt) => rt.statusKey).join(',') !== 'confirmed_active,ongoing,confirmed_archived,lost_and_cancelled')
      throw new Error(`unexpected number of requestTables [${sortByStatus}] [${requestTables.length}]`);
  }


  for (const requestTable of requestTables) {
    const { statusKey, rowCount, isExpanded } = requestTable;

    tablerows.push(
      <StatusHeaderRow
        key={`header_${statusKey}`}
        disabledPagination={disabledPagination}
        requestTable={requestTable}
        statusKey={statusKey}
        isFooter={false}
      />
    );
    if (isExpanded) {
      tablerows.push(<React.Fragment key={`requestrows_${statusKey}`}>{getRows(requestTable)}</React.Fragment>);
      if (rowCount && rowCount > 10) {
        tablerows.push(
          <StatusHeaderRow
            key={`footer_${statusKey}`}
            disabledPagination={disabledPagination}
            requestTable={requestTable}
            statusKey={statusKey}
            isFooter={true}
          />
        );
      }
    }

    isLoading ||= !!requestTable.isLoading;
  }



  return (
    <div className='container-fluid mx-3'>
      <Helmet><title>Request List</title></Helmet>


      <div style={{
        display: 'grid',
        gridTemplateColumns: '8rem 1fr',
        gap: '1em',
      }}>

        <div style={{
          gridRow: 2,
          gridColumn: 1,
        }}>

          <div className='tw-pb-4'>
            <div className={!sortByStatus ? 'selected' : ''}>
              <Link to={`/requests/list/${location.search}`}
                className='tw-block tw-no-underline tw-text-black'>
                Full list
              </Link>
            </div>
            <div className={sortByStatus ? 'selected' : ''}>
              <Link to={`/requests/worklog/${location.search}`}
                className='tw-block tw-no-underline tw-text-black'>
                Worklog
              </Link>
            </div>
          </div>

          <div>
            <div style={{
              fontWeight: 'bold',
              cursor: 'pointer',
            }}
              className={!columnFilterAppliedData.paramDesigner ? 'selected' : undefined}>
              <Link className='tw-text-black tw-no-underline tw-block' to={getNewUrl('designer', '')}>All designers</Link>
            </div>
            <ul className='filterList'>
              {travelDesignerList.map((designer) => {
                return (
                  <li key={designer.uid} className={columnFilterAppliedData.paramDesigner === designer.uid ? 'selected' : undefined}>
                    <Link className='tw-text-black tw-no-underline tw-block' to={getNewUrl('designer', designer.uid)}>
                      <i className='bi bi-chevron-right'></i>
                      {designer.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>

        </div>

        <div style={{
          gridRow: 1,
          gridColumn: 2,
        }}>


          <h2 className='my-4'>Request list</h2>

          <div className='d-flex my-3'>

            <div className='me-3'>
              <ButtonTW variant='bsGreen' to='/requests/add' textSize='md'>Create new request</ButtonTW>
            </div>

            <div className='me-5'>
              <ButtonTW variant='bsgreen_outline' to='/requests/search' textSize='md'>Search request list</ButtonTW>
            </div>

            <CheckboxSwitch id='switchShowAllColumns' label='Show all columns' className='mt-2 ms-4' checked={showAllColumns} onChange={(e) => {
              setShowAllColumns(e.target.checked);
            }} />

            {/* <CheckboxSwitch id='switchShowExploreSeries' label='Show Explore Series' className='mt-2 ms-4' checked={showExploreSeries} onChange={(e) => {
                setShowExploreSeries(e.target.checked)
              }} /> */}

            {userrole_isAdmin(userDetails.roles) && (
              <>
                <CheckboxSwitch id='switchShowSalesInfo1' label='Admin: Show Sales Info 1' className='mt-2 ms-4' checked={showSalesInfo1} onChange={(e) => {
                  setShowSalesInfo1(e.target.checked);
                }} />

                <CheckboxSwitch id='switchShowExpensesMiniTable' label='Admin: Show expenses mini table' className='mt-2 ms-4' checked={showExpensesMiniTable} onChange={(e) => {
                  setShowExpensesMiniTable(e.target.checked);
                }} />
              </>
            )}

          </div>

        </div>

        <div style={{
          gridRow: 2,
          gridColumn: 2,
        }}>



          {/* {showAllColumns && userrole_isDev(userDetails.roles) && true && (
            <>
              <div>Query row count: {rowCount}</div>
              <div>Rows shown: {requestList.length}</div>
            </>
          )} */}

          <div className='tw-flex tw-items-baseline tw-gap-2 tw-pb-2'>
            <div className='tw-py-2'>
              Filtering criteria:
            </div>
            <div className='tw-flex tw-items-baseline tw-gap-2'>
              {!columnFilterAppliedData.paramDesigner
                && !columnFilterAppliedData.appliedFilterTeamCategory
                && !columnFilterAppliedData.appliedFilterCustomerType
                && !columnFilterAppliedData.appliedFilterRequestCode
                && !columnFilterAppliedData.appliedFilterAgency
                && !columnFilterAppliedData.appliedFilterPaxName
                && !columnFilterAppliedData.appliedFilterCountry
                && !columnFilterAppliedData.appliedFilterTourStart
                && !columnFilterAppliedData.appliedFilterTourEnd
                && !columnFilterAppliedData.appliedFilterPaymentAmount
                && !columnFilterAppliedData.appliedFilterPaymentDate
                && (
                  <>None</>
                )}
              {columnFilterAppliedData.paramDesigner && (
                <FilteringCriteriaPill
                  name='Designer'
                  list={[travelDesignerList.find((u) => u.uid === columnFilterAppliedData.paramDesigner)?.name ?? '[unknown]']}
                  setSearchParam={(newParam) => setOneSearchParam('designer', newParam)}
                />
              )}
              {columnFilterAppliedData.appliedFilterTeamCategory && (
                <FilteringCriteriaPill
                  name='Category'
                  list={[...columnFilterAppliedData.appliedFilterTeamCategory]}
                  setSearchParam={(newParam) => setOneSearchParam('teamCategory', newParam)}
                />
              )}
              {columnFilterAppliedData.appliedFilterCustomerType && (
                <FilteringCriteriaPill
                  name='Customer type'
                  list={[...columnFilterAppliedData.appliedFilterCustomerType]}
                  setSearchParam={(newParam) => setOneSearchParam('customerType', newParam)}
                />
              )}
              {columnFilterAppliedData.appliedFilterRequestCode && (
                <FilteringCriteriaPill
                  name='Request code'
                  list={[...columnFilterAppliedData.appliedFilterRequestCode]}
                  setSearchParam={(newParam) => setOneSearchParam('requestCode', newParam)}
                />
              )}
              {columnFilterAppliedData.appliedFilterAgency && (
                <FilteringCriteriaPill
                  name='Agency'
                  list={[...columnFilterAppliedData.appliedFilterAgency].map((agencyId) => columnFilterCacheData.filterCacheAgencies?.find((a) => a.id === agencyId) ?? { id: agencyId, name: '[unknown]' })}
                  setSearchParam={(newParam) => setOneSearchParam('agencyId', newParam)}
                />
              )}
              {columnFilterAppliedData.appliedFilterPaxName && (
                <FilteringCriteriaPill
                  name='Pax name'
                  list={[...columnFilterAppliedData.appliedFilterPaxName]}
                  setSearchParam={(newParam) => setOneSearchParam('paxName', newParam)}
                />
              )}
              {columnFilterAppliedData.appliedFilterCountry && (
                <FilteringCriteriaPill
                  name='Country'
                  list={[...columnFilterAppliedData.appliedFilterCountry]}
                  setSearchParam={(newParam) => setOneSearchParam('country', newParam)}
                />
              )}
              {columnFilterAppliedData.appliedFilterTourStart && (
                <FilteringCriteriaPill
                  name='Tour start filter'
                  list={[getTreeFilterString(columnFilterAppliedData.appliedFilterTourStart)]}
                  setSearchParam={(newParam) => setOneSearchParam('tourStart', newParam)}
                />
              )}
              {columnFilterAppliedData.appliedFilterTourEnd && (
                <FilteringCriteriaPill
                  name='Tour end filter'
                  list={[getTreeFilterString(columnFilterAppliedData.appliedFilterTourEnd)]}
                  setSearchParam={(newParam) => setOneSearchParam('tourEnd', newParam)}
                />
              )}
              {columnFilterAppliedData.appliedFilterPaymentAmount && (
                <FilteringCriteriaPill
                  name='Payment amount'
                  list={[...columnFilterAppliedData.appliedFilterPaymentAmount].map((s) => formatNum(Number(s)))}
                  setSearchParam={(newParam) => setOneSearchParam('paymentAmount', newParam)}
                />
              )}
              {columnFilterAppliedData.appliedFilterPaymentDate && (
                <FilteringCriteriaPill
                  name='Payment date'
                  list={[...columnFilterAppliedData.appliedFilterPaymentDate]}
                  setSearchParam={(newParam) => setOneSearchParam('paymentDate', newParam)}
                />
              )}
            </div>
          </div>


          <RequestListTable
            tablerows={tablerows}
            showAllColumns={showAllColumns}
            showSalesInfo1={showSalesInfo1}
            showPayments={true}
            columnFilterAppliedData={columnFilterAppliedData}
            columnFilterCacheData={columnFilterCacheData}
            extendedProps={{
              freeeUnlocked,
              setFreeeUnlocked,
            }}
            isLoadingTableRows={isLoading}
            allowEditingTourStatus={true}
          />


        </div>
      </div>

    </div>
  );
}
