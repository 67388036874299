import { Helmet } from 'react-helmet-async';
import { getLoadingSpinnerOrNull } from 'src/components/Spinner/util_getLoadingSpinnerOrNull';
import { AgencyMonthlyStats } from './AgencyMonthlyStats';
import { useAgencyList } from './useAgencyList';



export function PageAgencyMonthlyStats() {

  const agencyList = useAgencyList();

  const loadingSpinner = getLoadingSpinnerOrNull([
    ['agency list', agencyList],
  ]);
  if (!agencyList)
    return loadingSpinner;

  return (
    <div className='container-fluid'>

      <Helmet><title>Agency Monthly Stats</title></Helmet>

      <h2 className='my-4'>Agency Monthly Stats</h2>

      <AgencyMonthlyStats agencyList={agencyList} />
    </div>
  );
}
