import { addDoc, collection, onSnapshot } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { ReactSortable } from 'react-sortablejs';
import { ButtonTW } from 'src/components/Buttons/ButtonTW';
import { DeleteButton } from 'src/components/Buttons/DeleteButton';
import { VariantNameType } from 'src/components/Buttons/util_buttontw';
import { EditableField } from 'src/components/EditableField/EditableField';
import { getLoadingSpinnerOrNull } from 'src/components/Spinner/util_getLoadingSpinnerOrNull';
import { UndoRedoButtons } from 'src/hooks/autosave/UndoRedoButtons';
import { useUndoRedo } from 'src/hooks/autosave/useUndoRedo';
import { autosaveDocument } from 'src/hooks/autosave/util_autosave';
import { useAppContext } from 'src/hooks/useAppContext';
import { PriceStructureType, ServicePriceAgeType, ServicePriceCatalogType, ServicePriceItemType, ServicePriceSeasonType } from 'src/types/types_supplierprices';
import { UserSimpleUidType } from 'src/types/types_user';
import { dateFormatJpShortWithTime } from 'src/util/dateformattools';
import { userrole_isDev } from 'src/util/user_roles';
import { convertServicePriceDates } from 'src/util/util_firestoredates';
import { formatNum } from 'src/util/util_formatnum';
import { stringCompare } from 'src/util/util_misc';
import { addMetadataModifiedServicePricesTable, getNewService, getNewServiceAge, getNewServicePriceItem, getNewServiceSeason, roundUp10 } from './util_serviceprices';


const buttonVariant: VariantNameType = 'blue_outline';

export function PageServicePrices() {

  const { db, userDetails, setDbError } = useAppContext();

  const [enableEditing, setEnableEditing] = useState(true);
  const [editedCell, setEditedCell] = useState<string | null>(null);
  const [reorderingMode, setReorderingMode] = useState<[string, 'priceItems' | 'seasons' | 'ages' | '']>(['', '']);

  const { addToCache, getUndoRedoHistoryChanges } = useUndoRedo<ServicePriceCatalogType>('servicepriceshistory');


  const [servicePrices, setServicePrices] = useState<ServicePriceCatalogType[]>();
  useEffect(() => {
    onSnapshot(
      collection(db, 'serviceprices'),
      (snapshot) => {

        const serviceList: ServicePriceCatalogType[] = [];
        for (const docu of snapshot.docs) {
          const serviceprice = { ...docu.data(), id: docu.id } as ServicePriceCatalogType;
          convertServicePriceDates(serviceprice);
          serviceList.push(serviceprice);
          if (serviceprice.history?.currentStepId) {
            addToCache(serviceprice.history.currentStepId, serviceprice);
          }
        }
        serviceList.sort((a, b) => stringCompare(a.serviceName, b.serviceName));
        setServicePrices(serviceList);
      },
      (err) => setDbError(`Error getting service prices: ${err}`)
    );
  }, [db, setDbError, addToCache]);



  const loadingSpinner = getLoadingSpinnerOrNull([
    ['prices', servicePrices],
  ]);
  if (!servicePrices)
    return loadingSpinner;


  const autosaveNewStep = async (
    pricesTable: ServicePriceCatalogType,
    userAction: string,
    updateObj: Partial<ServicePriceCatalogType>,
    sUndoWall: 'u' | 'UNDOWALL', // u = undoable
  ) => {
    return autosaveAnyStep(pricesTable, userAction, updateObj, false, undefined, sUndoWall);
  };

  const autosaveAnyStep = async (
    pricesTable: ServicePriceCatalogType,
    userAction: string,
    updateObj: any,
    isUndoRedo: boolean,
    undoRedoTargetStep: number | undefined,
    sUndoWall: 'u' | 'UNDOWALL', // u = undoable
  ) => {

    if (!enableEditing) {
      setDbError('Field change despite editing being disabled');
      throw new Error('Field change despite editing being disabled');
    }

    const userSimple: UserSimpleUidType = {
      uid: userDetails.id,
      email: userDetails.email,
      name: userDetails.displayNameEn,
    };

    autosaveDocument(
      updateObj,
      userAction,
      isUndoRedo,
      undoRedoTargetStep,
      sUndoWall,
      pricesTable.id,
      pricesTable.history,
      userSimple,
      db,
      'serviceprices',
      (updateObj: Partial<ServicePriceCatalogType>) => addMetadataModifiedServicePricesTable(updateObj, userSimple),
      null,
    )
      .catch((err) => setDbError(`Autosave [autosaveDocument] quotation id=${pricesTable.id} action=[${userAction}]`, err));
  };

  const autosaveUndoRedoStep = async (serviceprice: ServicePriceCatalogType, action: 'Undo' | 'Redo', targetStep: number) => {

    const undoRedoData = await getUndoRedoHistoryChanges(action, targetStep, serviceprice.history);
    if (!undoRedoData)
      // failed to retrieve history step from db
      return;

    const { updateObjHistory, targetStepObj } = undoRedoData;

    // fields that we remove in general:
    //   - id: we never save id as a field
    //   - _isDeleted: should always be false
    //   - history: specifically tweaked in a precise way by updateObjHistory
    //   - dateCreated, userCreated: immutable metadata
    //   - dateModified, userModified: metadata set upon save
    // general expense fields that we remove:
    //   - tbd

    // delete the field we specifically don't want:
    delete targetStepObj.id;
    delete targetStepObj._isDeleted;
    delete targetStepObj.history;
    // @ts-expect-error field parentDocumentId doesn't exist on type GeneralExpenseType
    delete targetStepObj.parentDocumentId;

    // delete targetStepObj.facilityIndex; // persistent field

    delete targetStepObj.dateCreated;
    delete targetStepObj.userCreated;
    delete targetStepObj.dateModified;
    delete targetStepObj.userModified;

    // add more immutable fields as needed:
    // here we should basically add all fields that are not editable through the CRUD UI

    const updateObj: Partial<ServicePriceCatalogType> = {
      ...targetStepObj,
      ...updateObjHistory,
    };

    autosaveAnyStep(
      serviceprice,
      action, // this isn't actually used
      updateObj,
      true,
      targetStep,
      'u', // undo/redo step is always undoable
    );
  };

  const cities: { cityName: string; duplicateCity: boolean; services: ServicePriceCatalogType[] }[] = [];
  let currentCity: string | undefined = undefined;
  for (const service of servicePrices) {
    if (service.city === currentCity) {
      cities.at(-1)!.services.push(service);
    } else {
      const duplicateCity = cities.some(city => city.cityName === service.city);
      cities.push({ cityName: service.city, duplicateCity, services: [service] });
      currentCity = service.city;
    }
  }

  return (
    <div className='tw-p-4'>

      <div className='tw-flex tw-gap-4'>
        <div className='__left_column tw-min-w-[14em]'>
          <div className='__service_list
           tw-sticky tw-top-[5.2em] tw-h-[calc(100vh-5.5em)] tw-overflow-scroll
            [&>ul]:tw-block [&>ul]:tw-p-0 [&>ul>li]:tw-block
            [&>ul>li>ul]:tw-block [&>ul>li>ul]:tw-p-0 [&>ul>li>ul>li]:tw-block
            '>

            <a className='tw-block tw-un tw-font-bold tw-text-center tw-border-0 tw-border-solid tw-border-slate-400 tw-mb-4 tw-bg-blue-200 tw-no-underline'
              href='#top'>
              Service List
            </a>
            <ul>
              {cities.map((city, iCity) => {
                return (
                  <li key={iCity}>
                    <b className={city.duplicateCity ? 'tw-italic' : ''}>{city.cityName || '[no city name]'}</b>
                    <ul>
                      {city.services.map(service => {
                        return (
                          <li key={service.id}>
                            <i className='bi bi-chevron-right'></i> <a href={`#${service.id}`} className='tw-no-underline'>{service.serviceName || '[no service name]'}</a>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                );
              })}
            </ul>

            <a className='tw-block tw-un tw-font-bold tw-text-center tw-border-0 tw-border-solid tw-border-slate-400 tw-mb-4 tw-bg-blue-200 tw-no-underline'
              href='#reorder-services'>
              Reorder Services
            </a>

          </div>

        </div>
        <div className='__right_column'>

          <a className='__anchor' id='top' style={{
            display: 'block',
            position: 'relative',
            left: '-14em',
            top: '-5em',
          }}></a>

          <h1 className='tw-text-[1.5em] tw-mt-4'>Service Prices</h1>

          <table className='
          [&>*>tr>*]:tw-border [&>*>tr>*]:tw-border-solid [&>*>tr>*]:tw-border-slate-400
          [&>*>tr>*]:tw-py-1 [&>*>tr>*]:tw-px-2'>
            <tbody>
              {servicePrices.map((serviceprice) => {

                const reorderingModeActive = reorderingMode[0] === serviceprice.id;

                const itemRows: JSX.Element[] = [];
                if (reorderingModeActive && reorderingMode[1] === 'priceItems') {
                  itemRows.push(
                    <tr key='reorder'>
                      <td colSpan={9}>
                        <ReactSortable animation={300} ghostClass='ghostClass' list={serviceprice.priceItems} setList={(newItemList) => {
                          if (newItemList.map(row => row.id).join(',') === serviceprice.priceItems.map(row => row.id).join(','))
                            // no change
                            return;

                          const updateObj: Partial<ServicePriceCatalogType> = {
                            priceItems: newItemList,
                          };

                          autosaveNewStep(serviceprice, 'Change item order', updateObj, 'u');
                          setEditedCell(null);

                          // to avoid glitching, temporarily set the new order in the local state too
                          setServicePrices(servicePrices.map(service => {
                            if (service.id === serviceprice.id) {
                              const newService: ServicePriceCatalogType = { ...service, priceItems: newItemList };
                              return newService;
                            } else {
                              return service;
                            }
                          }));
                        }} className='tw-flex tw-flex-col tw-gap-1 tw-w-64'>
                          {serviceprice.priceItems.map((priceItem, index) => (
                            <div key={index} className='tw-border tw-border-solid tw-border-slate-500 tw-p-2 tw-rounded tw-bg-white tw-cursor-default'>
                              {priceItem.priceItemName}
                            </div>
                          ))}
                        </ReactSortable>
                      </td>
                    </tr>
                  );
                } else {
                  // not reordering price items

                  serviceprice.priceItems.forEach((priceItem, iPriceItem) => {

                    const rowSpanPriceItem = priceItem.seasons.reduce((acc, season) => acc + season.ages.length, 0);

                    priceItem.seasons.forEach((season, iSeason) => {

                      const rowSpanSeason = season.ages.length;

                      season.ages.forEach((age, iAge) => {

                        let priceCells_top: JSX.Element;
                        let priceCells_bottom: JSX.Element | null;

                        if (serviceprice.priceStructure === 'perPersonVariable') {

                          priceCells_top =
                            <>
                              {(['pricePerPerson2pax', 'pricePerPerson3pax', 'pricePerPerson4pax'] as const).map((labelForNPax, iColumn) => {
                                return (
                                  <td key={labelForNPax} rowSpan={2}>
                                    <EditableField
                                      tableid={serviceprice.id}
                                      rowid={`priceItem_${iPriceItem}_season_${iSeason}_age_${iAge}`}
                                      fieldname={labelForNPax}
                                      validationType={'number'}
                                      currentValue={age[labelForNPax]}
                                      isClickableToEdit={enableEditing}
                                      editedCell={editedCell}
                                      setEditedCell={setEditedCell}
                                      callbackCommitChange={(dbvalue: any) => {
                                        const newValue = dbvalue === null || dbvalue === undefined || dbvalue === '' ? null : Number(dbvalue);
                                        if (typeof newValue === 'number' && isNaN(newValue))
                                          return;
                                        const updateObj: Partial<ServicePriceCatalogType> = {
                                          priceItems: serviceprice.priceItems.map((priceItem, index) => {
                                            if (index === iPriceItem) {
                                              const newPriceItem: ServicePriceItemType = {
                                                ...priceItem,
                                                seasons: priceItem.seasons.map((season, index) => {
                                                  if (index === iSeason) {
                                                    const newSeason: ServicePriceSeasonType = {
                                                      ...season,
                                                      ages: season.ages.map((age, index) => {
                                                        if (index === iAge) {
                                                          const newAge: ServicePriceAgeType = {
                                                            ...age,
                                                            [labelForNPax]: newValue,
                                                            pricePerPerson: null,
                                                            pricePerGroup: null,
                                                          };
                                                          return newAge;
                                                        } else {
                                                          return age;
                                                        }
                                                      }),
                                                    };
                                                    return newSeason;
                                                  } else {
                                                    return season;
                                                  }
                                                }),
                                              };
                                              return newPriceItem;
                                            } else {
                                              return priceItem;
                                            }
                                          }),
                                        };
                                        autosaveNewStep(serviceprice, `Change season ${season} age ${age} forNpax ${labelForNPax} price to ‘${newValue}’`, updateObj, 'u');
                                        setEditedCell(null);
                                      }}
                                    />
                                  </td>
                                );
                              })}
                            </>;
                          priceCells_bottom = null;

                        } else {

                          if (serviceprice.priceStructure === 'perPerson') {

                            priceCells_top =
                              <td colSpan={3} rowSpan={2}>
                                <div className='tw-flex tw-gap-2'>
                                  <EditableField
                                    tableid={serviceprice.id}
                                    rowid={`priceItem_${iPriceItem}_season_${iSeason}_age_${iAge}`}
                                    fieldname={'pricePerPerson'}
                                    validationType={'number'}
                                    currentValue={age.pricePerPerson}
                                    isClickableToEdit={enableEditing}
                                    editedCell={editedCell}
                                    setEditedCell={setEditedCell}
                                    callbackCommitChange={(dbvalue: any) => {
                                      const newValue = dbvalue === null || dbvalue === undefined || dbvalue === '' ? null : Number(dbvalue);
                                      if (typeof newValue === 'number' && isNaN(newValue))
                                        return;
                                      const updateObj: Partial<ServicePriceCatalogType> = {
                                        priceItems: serviceprice.priceItems.map((priceItem, index) => {
                                          if (index === iPriceItem) {
                                            const newPriceItem: ServicePriceItemType = {
                                              ...priceItem,
                                              seasons: priceItem.seasons.map((season, index) => {
                                                if (index === iSeason) {
                                                  const newSeason: ServicePriceSeasonType = {
                                                    ...season,
                                                    ages: season.ages.map((age, index) => {
                                                      if (index === iAge) {
                                                        const newAge: ServicePriceAgeType = {
                                                          ...age,
                                                          pricePerPerson: newValue,
                                                          pricePerPerson2pax: newValue,
                                                          pricePerPerson3pax: newValue,
                                                          pricePerPerson4pax: newValue,
                                                          pricePerGroup: null,
                                                        };
                                                        return newAge;
                                                      } else {
                                                        return age;
                                                      }
                                                    }),
                                                  };
                                                  return newSeason;
                                                } else {
                                                  return season;
                                                }
                                              }),
                                            };
                                            return newPriceItem;
                                          } else {
                                            return priceItem;
                                          }
                                        }),
                                      };
                                      autosaveNewStep(serviceprice, `Change season ${season} age ${age} price per person to ‘${newValue}’`, updateObj, 'u');
                                      setEditedCell(null);
                                    }}
                                  />
                                  <div>per person</div>
                                </div>
                              </td>;

                          } else if (serviceprice.priceStructure === 'perGroup') {

                            priceCells_top =
                              <td colSpan={3}>
                                <div className='tw-flex tw-gap-2'>
                                  <EditableField
                                    tableid={serviceprice.id}
                                    rowid={`priceItem_${iPriceItem}_season_${iSeason}_age_${iAge}`}
                                    fieldname={'pricePerGroup'}
                                    validationType={'number'}
                                    currentValue={age.pricePerGroup}
                                    isClickableToEdit={enableEditing}
                                    editedCell={editedCell}
                                    setEditedCell={setEditedCell}
                                    callbackCommitChange={(dbvalue: any) => {
                                      const newValue = dbvalue === null || dbvalue === undefined || dbvalue === '' ? null : Number(dbvalue);
                                      if (typeof newValue === 'number' && isNaN(newValue))
                                        return;
                                      const updateObj: Partial<ServicePriceCatalogType> = {
                                        priceItems: serviceprice.priceItems.map((priceItem, index) => {
                                          if (index === iPriceItem) {
                                            const newPriceItem: ServicePriceItemType = {
                                              ...priceItem,
                                              seasons: priceItem.seasons.map((season, index) => {
                                                if (index === iSeason) {
                                                  const newSeason: ServicePriceSeasonType = {
                                                    ...season,
                                                    ages: season.ages.map((age, index) => {
                                                      if (index === iAge) {
                                                        const newAge: ServicePriceAgeType = {
                                                          ...age,
                                                          pricePerGroup: newValue,
                                                          pricePerPerson2pax: newValue !== null ? roundUp10(newValue / 2) : null,
                                                          pricePerPerson3pax: newValue !== null ? roundUp10(newValue / 3) : null,
                                                          pricePerPerson4pax: newValue !== null ? roundUp10(newValue / 4) : null,
                                                          pricePerPerson: null,
                                                        };
                                                        return newAge;
                                                      } else {
                                                        return age;
                                                      }
                                                    }),
                                                  };
                                                  return newSeason;
                                                } else {
                                                  return season;
                                                }
                                              }),
                                            };
                                            return newPriceItem;
                                          } else {
                                            return priceItem;
                                          }
                                        }),
                                      };
                                      autosaveNewStep(serviceprice, `Change season ${season} age ${age} price per person to ‘${newValue}’`, updateObj, 'u');
                                      setEditedCell(null);
                                    }}
                                  />
                                  <div>per group</div>
                                </div>
                              </td>;

                          } else {
                            throw new Error('unreachable');
                          }

                          let err2 = false, err3 = false, err4 = false;
                          if (serviceprice.priceStructure === 'perPerson') {
                            err2 = age.pricePerPerson2pax !== age.pricePerPerson;
                            err3 = age.pricePerPerson3pax !== age.pricePerPerson;
                            err4 = age.pricePerPerson4pax !== age.pricePerPerson;
                          } else if (serviceprice.priceStructure === 'perGroup') {
                            if (age.pricePerGroup === null) {
                              err2 = age.pricePerPerson2pax !== null;
                              err3 = age.pricePerPerson3pax !== null;
                              err4 = age.pricePerPerson4pax !== null;
                            } else {
                              err2 = age.pricePerPerson2pax !== roundUp10(age.pricePerGroup / 2);
                              err3 = age.pricePerPerson3pax !== roundUp10(age.pricePerGroup / 3);
                              err4 = age.pricePerPerson4pax !== roundUp10(age.pricePerGroup / 4);
                            }
                          }

                          priceCells_bottom =
                            serviceprice.priceStructure === 'perGroup' ? (
                              <>
                                <td className={err2 ? 'tw-bg-red-300' : ''}>{formatNum(age.pricePerPerson2pax)}</td>
                                <td className={err3 ? 'tw-bg-red-300' : ''}>{formatNum(age.pricePerPerson3pax)}</td>
                                <td className={err4 ? 'tw-bg-red-300' : ''}>{formatNum(age.pricePerPerson4pax)}</td>
                              </>
                            ) : null;
                        }

                        itemRows.push(
                          <React.Fragment key={`row_${iPriceItem}_${iSeason}_${iAge}`}>
                            <tr className='[&>td]:tw-bg-white'>
                              {iSeason === 0 && iAge === 0 && (
                                <td rowSpan={2 * rowSpanPriceItem}>
                                  <EditableField
                                    tableid={serviceprice.id}
                                    rowid={`priceItem_${iPriceItem}`}
                                    fieldname={'priceItemName'}
                                    validationType={''}
                                    currentValue={priceItem.priceItemName || ''}
                                    isClickableToEdit={enableEditing}
                                    editedCell={editedCell}
                                    setEditedCell={setEditedCell}
                                    callbackCommitChange={(dbvalue: any) => {
                                      const updateObj: Partial<ServicePriceCatalogType> = {
                                        priceItems: serviceprice.priceItems.map((priceItem, index) => {
                                          if (index === iPriceItem) {
                                            const newPriceItem: ServicePriceItemType = {
                                              ...priceItem,
                                              priceItemName: dbvalue,
                                            };
                                            return newPriceItem;
                                          } else {
                                            return priceItem;
                                          }
                                        }),
                                      };
                                      autosaveNewStep(serviceprice, `Change item name to ‘${dbvalue}’`, updateObj, 'u');
                                      setEditedCell(null);
                                    }}
                                  />

                                </td>
                              )}
                              {(reorderingModeActive && reorderingMode[1] === 'seasons' && priceItem.seasons.length > 1) ? (
                                iSeason === 0 && (
                                  <td colSpan={7} rowSpan={priceItem.seasons.length}>
                                    <ReactSortable animation={300} ghostClass='ghostClass' list={priceItem.seasons.map((row, index) => ({ ...row, id: index }))} setList={(newList) => {
                                      if (newList.map(row => row.id).join(',') === [...Array(newList.length).keys()].join(','))
                                        // no change
                                        return;

                                      const newSeasonList = newList.map(row => {
                                        const { id, ...rest } = row;
                                        return rest;
                                      });

                                      const updateObj: Partial<ServicePriceCatalogType> = {
                                        priceItems: serviceprice.priceItems.map((priceItem, index) => {
                                          if (index === iPriceItem) {
                                            const newSeason: ServicePriceItemType = {
                                              ...priceItem,
                                              seasons: newSeasonList,
                                            };
                                            return newSeason;
                                          } else {
                                            return priceItem;
                                          }
                                        }),
                                      };

                                      autosaveNewStep(serviceprice, 'Change season order', updateObj, 'u');
                                      setEditedCell(null);

                                      // to avoid glitching, temporarily set the new order in the local state too
                                      setServicePrices(servicePrices.map(service => {
                                        if (service.id === serviceprice.id) {
                                          const newService: ServicePriceCatalogType = {
                                            ...service,
                                            priceItems: service.priceItems.map((priceItem, index) => {
                                              if (index === iPriceItem) {
                                                const newPriceItem: ServicePriceItemType = {
                                                  ...priceItem,
                                                  seasons: newSeasonList,
                                                };
                                                return newPriceItem;
                                              } else {
                                                return priceItem;
                                              }
                                            }),
                                          };
                                          return newService;
                                        } else {
                                          return service;
                                        }
                                      }));
                                    }} className='tw-flex tw-flex-col tw-gap-1 tw-w-32'>
                                      {priceItem.seasons.map((season, index) => (
                                        <div key={index} className='tw-border tw-border-solid tw-border-slate-500 tw-p-2 tw-rounded tw-bg-white tw-cursor-default'>
                                          TODO
                                        </div>
                                      ))}
                                    </ReactSortable>
                                  </td>
                                )
                              ) : ( // not reordering seasons
                                <>
                                  {iAge === 0 && (
                                    <td rowSpan={2 * rowSpanSeason}>
                                      <EditableField
                                        tableid={serviceprice.id}
                                        rowid={`priceItem_${iPriceItem}_season_${iSeason}`}
                                        fieldname={'seasonName'}
                                        validationType={''}
                                        currentValue={season.seasonName}
                                        isClickableToEdit={enableEditing}
                                        editedCell={editedCell}
                                        setEditedCell={setEditedCell}
                                        callbackCommitChange={(dbvalue: any) => {
                                          const updateObj: Partial<ServicePriceCatalogType> = {
                                            priceItems: serviceprice.priceItems.map((priceItem, index) => {
                                              if (index === iPriceItem) {
                                                const newPriceItem: ServicePriceItemType = {
                                                  ...priceItem,
                                                  seasons: priceItem.seasons.map((season, index) => {
                                                    if (index === iSeason) {
                                                      const newSeason: ServicePriceSeasonType = {
                                                        ...season,
                                                        seasonName: dbvalue,
                                                      };
                                                      return newSeason;
                                                    } else {
                                                      return season;
                                                    }
                                                  }),
                                                };
                                                return newPriceItem;
                                              } else {
                                                return priceItem;
                                              }
                                            }),
                                          };
                                          autosaveNewStep(serviceprice, `Change season name to ‘${dbvalue}’`, updateObj, 'u');
                                          setEditedCell(null);
                                        }}
                                      />
                                    </td>
                                  )}
                                  <td rowSpan={2}>
                                    <EditableField
                                      tableid={serviceprice.id}
                                      rowid={`priceItem_${iPriceItem}_season_${iSeason}_age_${iAge}`}
                                      fieldname={'ageName'}
                                      validationType={''}
                                      currentValue={age.ageName}
                                      isClickableToEdit={enableEditing}
                                      editedCell={editedCell}
                                      setEditedCell={setEditedCell}
                                      callbackCommitChange={(dbvalue: any) => {
                                        const updateObj: Partial<ServicePriceCatalogType> = {
                                          priceItems: serviceprice.priceItems.map((priceItem, index) => {
                                            if (index === iPriceItem) {
                                              const newPriceItem: ServicePriceItemType = {
                                                ...priceItem,
                                                seasons: priceItem.seasons.map((season, index) => {
                                                  if (index === iSeason) {
                                                    const newSeason: ServicePriceSeasonType = {
                                                      ...season,
                                                      ages: season.ages.map((age, index) => {
                                                        if (index === iAge) {
                                                          const newAge: ServicePriceAgeType = {
                                                            ...age,
                                                            ageName: dbvalue,
                                                          };
                                                          return newAge;
                                                        } else {
                                                          return age;
                                                        }
                                                      }),
                                                    };
                                                    return newSeason;
                                                  } else {
                                                    return season;
                                                  }
                                                }),
                                              };
                                              return newPriceItem;
                                            } else {
                                              return priceItem;
                                            }
                                          }),
                                        };
                                        autosaveNewStep(serviceprice, `Change age name to ‘${dbvalue}’`, updateObj, 'u');
                                        setEditedCell(null);
                                      }}
                                    />
                                  </td>

                                  {priceCells_top}
                                  <td rowSpan={2}>
                                    <EditableField
                                      tableid={serviceprice.id}
                                      rowid={`priceItem_${iPriceItem}_season_${iSeason}_age_${iAge}`}
                                      fieldname={'notes'}
                                      validationType={''}
                                      currentValue={age.notes}
                                      isClickableToEdit={enableEditing}
                                      editedCell={editedCell}
                                      setEditedCell={setEditedCell}
                                      callbackCommitChange={(dbvalue: any) => {
                                        const updateObj: Partial<ServicePriceCatalogType> = {
                                          priceItems: serviceprice.priceItems.map((priceItem, index) => {
                                            if (index === iPriceItem) {
                                              const newPriceItem: ServicePriceItemType = {
                                                ...priceItem,
                                                seasons: priceItem.seasons.map((season, index) => {
                                                  if (index === iSeason) {
                                                    const newSeason: ServicePriceSeasonType = {
                                                      ...season,
                                                      ages: season.ages.map((age, index) => {
                                                        if (index === iAge) {
                                                          const newAge: ServicePriceAgeType = {
                                                            ...age,
                                                            notes: dbvalue,
                                                          };
                                                          return newAge;
                                                        } else {
                                                          return age;
                                                        }
                                                      }),
                                                    };
                                                    return newSeason;
                                                  } else {
                                                    return season;
                                                  }
                                                }),
                                              };
                                              return newPriceItem;
                                            } else {
                                              return priceItem;
                                            }
                                          }),
                                        };
                                        autosaveNewStep(serviceprice, `Change season ${season} age ${age} notes to ‘${dbvalue}’`, updateObj, 'u');
                                        setEditedCell(null);
                                      }}
                                    />
                                  </td>
                                  <td rowSpan={2}>
                                    {/* actions cell */}
                                    <div className='tw-flex tw-gap-1'>
                                      {season.ages.length > 1 ? (
                                        <ButtonTW variant={buttonVariant} className='tw-whitespace-nowrap' onClick={() => {
                                          const updateObj: Partial<ServicePriceCatalogType> = {
                                            priceItems: serviceprice.priceItems.map((priceItem, index) => {
                                              if (index === iPriceItem) {
                                                const newPriceItem: ServicePriceItemType = {
                                                  ...priceItem,
                                                  seasons: priceItem.seasons.map((season, index) => {
                                                    if (index === iSeason) {
                                                      const newSeason: ServicePriceSeasonType = {
                                                        ...season,
                                                        ages: season.ages.filter((_, index) => index !== iAge),
                                                      };
                                                      return newSeason;
                                                    } else {
                                                      return season;
                                                    }
                                                  }),
                                                };
                                                return newPriceItem;
                                              } else {
                                                return priceItem;
                                              }
                                            }),
                                          };
                                          autosaveNewStep(serviceprice, 'Delete age', updateObj, 'u');
                                        }}>Delete age</ButtonTW>
                                      ) : priceItem.seasons.length > 1 ? (
                                        <ButtonTW variant={buttonVariant} className='tw-whitespace-nowrap' onClick={() => {
                                          const updateObj: Partial<ServicePriceCatalogType> = {
                                            priceItems: serviceprice.priceItems.map((priceItem, index) => {
                                              if (index === iPriceItem) {
                                                const newPriceItem: ServicePriceItemType = {
                                                  ...priceItem,
                                                  seasons: priceItem.seasons.filter((_, index) => index !== iSeason),
                                                };
                                                return newPriceItem;
                                              } else {
                                                return priceItem;
                                              }
                                            }),
                                          };
                                          autosaveNewStep(serviceprice, 'Delete season', updateObj, 'u');
                                        }}>Delete season</ButtonTW>
                                      ) : (
                                        <ButtonTW variant={buttonVariant} className='tw-whitespace-nowrap' onClick={() => {
                                          const updateObj: Partial<ServicePriceCatalogType> = {
                                            priceItems: serviceprice.priceItems.filter((_, index) => index !== iPriceItem),
                                          };
                                          autosaveNewStep(serviceprice, 'Delete price item', updateObj, 'u');
                                        }}>Delete price item</ButtonTW>
                                      )}
                                      {iSeason === priceItem.seasons.length - 1 && iAge === season.ages.length - 1 && (
                                        <ButtonTW variant={buttonVariant} className='tw-whitespace-nowrap' onClick={() => {
                                          const updateObj: Partial<ServicePriceCatalogType> = {
                                            priceItems: serviceprice.priceItems.map((priceItem, index) => {
                                              if (index === iPriceItem) {
                                                const newPriceItem: ServicePriceItemType = {
                                                  ...priceItem,
                                                  seasons: [
                                                    ...priceItem.seasons.slice(0, iSeason + 1),
                                                    getNewServiceSeason(),
                                                    ...priceItem.seasons.slice(iSeason + 1),
                                                  ],
                                                };
                                                return newPriceItem;
                                              } else {
                                                return priceItem;
                                              }
                                            }),
                                          };
                                          autosaveNewStep(serviceprice, 'Add new season', updateObj, 'u');
                                        }}>Add season</ButtonTW>
                                      )}
                                      {iAge === season.ages.length - 1 && (
                                        <ButtonTW variant={buttonVariant} className='tw-whitespace-nowrap' onClick={() => {
                                          const updateObj: Partial<ServicePriceCatalogType> = {
                                            priceItems: serviceprice.priceItems.map((priceItem, index) => {
                                              if (index === iPriceItem) {
                                                const newPriceItem: ServicePriceItemType = {
                                                  ...priceItem,
                                                  seasons: priceItem.seasons.map((season, index) => {
                                                    if (index === iSeason) {
                                                      const newSeason: ServicePriceSeasonType = {
                                                        ...season,
                                                        ages: [
                                                          ...season.ages.slice(0, iAge + 1),
                                                          getNewServiceAge(),
                                                          ...season.ages.slice(iAge + 1),
                                                        ],
                                                      };
                                                      return newSeason;
                                                    } else {
                                                      return season;
                                                    }
                                                  }),
                                                };
                                                return newPriceItem;
                                              } else {
                                                return priceItem;
                                              }
                                            }),
                                          };
                                          autosaveNewStep(serviceprice, 'Add new season', updateObj, 'u');
                                        }}>Add age</ButtonTW>
                                      )}
                                    </div>
                                  </td>
                                  {iSeason === 0 && iAge === 0 && (
                                    <td rowSpan={2 * rowSpanPriceItem}>
                                      {priceItem.itemMappings && priceItem.itemMappings.map((mapping, index) =>
                                        <div key={index}>
                                          <span className='tw-mr-2'>
                                            {mapping.serviceName} {mapping.details && ` [${mapping.details}]`}
                                          </span>
                                          <DeleteButton onClick={() => {
                                            const newMappings = priceItem.itemMappings.filter(mapping => mapping !== mapping);
                                            const updateObj: Partial<ServicePriceCatalogType> = {
                                              priceItems: serviceprice.priceItems.map((pi, index) => {
                                                if (index === iPriceItem) {
                                                  const newPriceItem: ServicePriceItemType = {
                                                    ...pi,
                                                    itemMappings: newMappings,
                                                  };
                                                  return newPriceItem;
                                                } else {
                                                  return pi;
                                                }
                                              }),
                                            };
                                            autosaveNewStep(serviceprice, `Remove service item mapping ‘${mapping}’`, updateObj, 'u');
                                          }} />
                                        </div>
                                      )}
                                    </td>
                                  )}
                                </>
                              )}
                            </tr>
                            <tr>
                              {priceCells_bottom}
                            </tr>
                          </React.Fragment>
                        );
                      });
                    });
                  });
                }

                return (
                  <React.Fragment key={serviceprice.id}>
                    <tr>
                      <td rowSpan={2 + 2 * itemRows.length + 1}
                        className='tw-align-top tw-font-bold tw-bg-slate-300'>
                        <a className='__anchor' id={serviceprice.id} style={{
                          display: 'block',
                          position: 'relative',
                          left: '-14em',
                          top: '-5em',
                        }}></a>
                        <EditableField
                          tableid={serviceprice.id}
                          rowid={'serviceInfo'}
                          fieldname={'city'}
                          validationType={''}
                          currentValue={serviceprice.city}
                          isClickableToEdit={enableEditing}
                          editedCell={editedCell}
                          setEditedCell={setEditedCell}
                          callbackCommitChange={(dbvalue: any) => {
                            const updateObj: Partial<ServicePriceCatalogType> = {
                              city: dbvalue,
                            };
                            autosaveNewStep(serviceprice, `Change service city to ‘${dbvalue}’`, updateObj, 'u');
                            setEditedCell(null);
                          }}
                          hasButtonForEditing={true}
                        />
                      </td>
                      <td colSpan={9} className='tw-bg-slate-100'>
                        <div className='tw-flex tw-justify-between'>

                          <table className='[&>tbody>tr>td:first-child]:tw-pe-16'>
                            <tbody>
                              <tr className='tw-font-bold'>
                                <td>Service name:</td>
                                <td>
                                  <EditableField
                                    tableid={serviceprice.id}
                                    rowid={'serviceInfo'}
                                    fieldname={'serviceName'}
                                    validationType={''}
                                    currentValue={serviceprice.serviceName}
                                    isClickableToEdit={enableEditing}
                                    editedCell={editedCell}
                                    setEditedCell={setEditedCell}
                                    callbackCommitChange={(dbvalue: any) => {
                                      const updateObj: Partial<ServicePriceCatalogType> = {
                                        serviceName: dbvalue,
                                      };
                                      autosaveNewStep(serviceprice, `Change service name to ‘${dbvalue}’`, updateObj, 'u');
                                      setEditedCell(null);
                                    }}
                                    hasButtonForEditing={true}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>Service notes:</td>
                                <td>
                                  <EditableField
                                    tableid={serviceprice.id}
                                    rowid={'serviceInfo'}
                                    fieldname={'serviceNotes'}
                                    validationType={''}
                                    currentValue={serviceprice.serviceNotes}
                                    isClickableToEdit={enableEditing}
                                    editedCell={editedCell}
                                    setEditedCell={setEditedCell}
                                    callbackCommitChange={(dbvalue: any) => {
                                      const updateObj: Partial<ServicePriceCatalogType> = {
                                        serviceNotes: dbvalue,
                                      };
                                      autosaveNewStep(serviceprice, `Change service notes to ‘${dbvalue}’`, updateObj, 'u');
                                      setEditedCell(null);
                                    }}
                                    hasButtonForEditing={true}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>Description:</td>
                                <td>
                                  <EditableField
                                    tableid={serviceprice.id}
                                    rowid={'serviceInfo'}
                                    fieldname={'description'}
                                    validationType={''}
                                    currentValue={serviceprice.description}
                                    isClickableToEdit={enableEditing}
                                    editedCell={editedCell}
                                    setEditedCell={setEditedCell}
                                    callbackCommitChange={(dbvalue: any) => {
                                      const updateObj: Partial<ServicePriceCatalogType> = {
                                        description: dbvalue,
                                      };
                                      autosaveNewStep(serviceprice, `Change service description to ‘${dbvalue}’`, updateObj, 'u');
                                      setEditedCell(null);
                                    }}
                                    hasButtonForEditing={true}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>Price structure</td>
                                <td>
                                  <select value={serviceprice.priceStructure} onChange={(e) => {
                                    const priceStructure = e.target.value as PriceStructureType;
                                    const updateObj: Partial<ServicePriceCatalogType> = {
                                      priceStructure,
                                      priceItems: serviceprice.priceItems.map(priceItem => {
                                        return {
                                          ...priceItem,
                                          seasons: priceItem.seasons.map(season => {
                                            return {
                                              ...season,
                                              ages: season.ages.map(age => {
                                                return {
                                                  ...age,
                                                  pricePerPerson: priceStructure === 'perPerson' ? age.pricePerPerson2pax : null,
                                                  // pricePerPerson2pax: null,
                                                  // pricePerPerson3pax: null,
                                                  // pricePerPerson4pax: null,
                                                  pricePerGroup: priceStructure === 'perGroup' ? (age.pricePerPerson2pax !== null ? (age.pricePerPerson2pax * 2) : null) : null,
                                                };
                                              }),
                                            };
                                          }),
                                        };
                                      }),
                                    };
                                    autosaveNewStep(serviceprice, `Change price structure to ‘${priceStructure}’`, updateObj, 'u');
                                  }}>
                                    <option value='perPerson'>Per Person (single price)</option>
                                    <option value='perGroup'>Per Group</option>
                                    <option value='perPersonVariable'>Per Person (custom)</option>
                                  </select>
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <div className='tw-flex tw-p-2 tw-gap-2'>
                            {userrole_isDev(userDetails.roles) && serviceprice.id}
                            <UndoRedoButtons
                              whiteBarActive={true}
                              enableEditing={enableEditing}
                              autosaveUndoRedoStep={(action: 'Undo' | 'Redo', targetStep: number) => autosaveUndoRedoStep(serviceprice, action, targetStep)}
                              history={serviceprice.history}
                            />
                          </div>
                        </div>

                      </td>
                    </tr>
                    <tr>
                      <th>Service item</th>
                      <th>Season</th>
                      <th>Age</th>
                      <th className='tw-bg-slate-200'>Price per person for 2 pax</th>
                      <th className='tw-bg-slate-200'>Price per person for 3 pax</th>
                      <th className='tw-bg-slate-200'>Price per person for 4 pax</th>
                      <th>Notes</th>
                      <th>Actions</th>
                      <th>Mappings</th>
                    </tr>
                    {itemRows}
                    <tr>
                      <td colSpan={9}>
                        <div className='tw-flex tw-gap-1'>
                          {!reorderingModeActive ? (
                            <>
                              <ButtonTW variant={buttonVariant} onClick={() => {
                                const updateObj: Partial<ServicePriceCatalogType> = {
                                  priceItems: [
                                    ...serviceprice.priceItems,
                                    getNewServicePriceItem(),
                                  ],
                                };
                                autosaveNewStep(serviceprice, 'Add new price item', updateObj, 'u');
                              }}>Add price item</ButtonTW>
                              <ButtonTW variant={buttonVariant} onClick={() => {
                                setReorderingMode([serviceprice.id, 'priceItems']);
                              }}>Reorder price items</ButtonTW>
                              <ButtonTW variant={buttonVariant} onClick={() => {
                                setReorderingMode([serviceprice.id, 'seasons']);
                              }}>Reorder seasons</ButtonTW>
                              <ButtonTW variant={buttonVariant} onClick={() => {
                                setReorderingMode([serviceprice.id, 'ages']);
                              }}>Reorder ages</ButtonTW>
                            </>
                          ) : (
                            <ButtonTW variant={buttonVariant} onClick={() => {
                              setReorderingMode(['', '']);
                            }}>Reordering done</ButtonTW>
                          )}
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={9} className='!tw-border-l-0 !tw-border-r-0'></td>
                    </tr>

                  </React.Fragment>
                );

              })}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={11}>
                  <ButtonTW variant={buttonVariant} onClick={() => {
                    const userSimple: UserSimpleUidType = {
                      uid: userDetails.id,
                      email: userDetails.email,
                      name: userDetails.displayNameEn,
                    };

                    const newService = getNewService(userSimple);
                    addDoc(collection(db, 'serviceprices'), newService);
                  }}>Add Service</ButtonTW>
                </td>
              </tr>
            </tfoot>
          </table>


          <a className='__anchor' id='reorder-services' style={{
            display: 'block',
            position: 'relative',
            left: '-14em',
            top: '-5em',
          }}></a>
          <h1 className='tw-text-[1.5em] tw-mt-8'>Service List</h1>

          <div>
            <table className='
                  [&>*>tr>*]:tw-border [&>*>tr>*]:tw-border-solid [&>*>tr>*]:tw-border-slate-400
                  [&>*>tr>*]:tw-py-1 [&>*>tr>*]:tw-px-2'>
              <thead>
                <tr className='[&>th]:tw-bg-slate-400'>
                  <th>City</th>
                  <th>Service name</th>
                  <th>Last modified</th>
                  <th>User</th>
                </tr>
              </thead>
              <tbody>
                {cities.map((city) => {
                  return city.services.map((service, index) => (
                    <tr key={service.id}>
                      {index === 0 && (
                        <td rowSpan={city.services.length} className='tw-bg-slate-300'>
                          <b>{city.cityName}</b>
                        </td>
                      )}
                      <td className='tw-bg-white'>
                        {service.serviceName}
                      </td>
                      <td className='tw-bg-slate-200'>
                        {dateFormatJpShortWithTime(service.dateModified)}
                      </td>
                      <td className='tw-bg-slate-200'>
                        {service.userModified.name}
                      </td>
                    </tr>
                  ));
                })}
              </tbody>
            </table>
            <ButtonTW variant={buttonVariant} onClick={() => setReorderingMode(['serviceList', ''])}>Reorder services</ButtonTW>
          </div>

        </div>
      </div>

    </div>
  );
}
