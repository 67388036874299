import { useState } from 'react';
import { useAppContext } from 'src/hooks/useAppContext';
import { FileStoragePathAndDownloadUrlType } from 'src/types/types_invoices';
import { FileDownloadButton } from './FileDownloadButton';
import { FileUploadButton } from './FileUploadButton';


interface FileDownloadUploadButtonsProps {
  isExpandable: boolean;
  isReadOnly?: boolean;
  listFiles: FileStoragePathAndDownloadUrlType[];
  uploadButtonId: string;
  storageBaseFolder: string;
  callbackUploadSuccess: ((uploadedFilePaths: FileStoragePathAndDownloadUrlType[]) => void) | null; // null if isReadOnly
  handleDeleteFile: ((file: FileStoragePathAndDownloadUrlType) => void) | null; // if null, deletion column will not be shown
  loginfo: string;
}

export function FileDownloadUploadButtons({
  isExpandable,
  isReadOnly,
  listFiles,
  uploadButtonId,
  storageBaseFolder,
  callbackUploadSuccess,
  handleDeleteFile,
  loginfo,
}: FileDownloadUploadButtonsProps) {

  const { userDetails, db, setDbError, cloudFunctions, _lang } = useAppContext();

  // const [attachmentDeletionMode, setAttachmentDeletionMode] = useState(false)
  // TODO: implement attachment deletion

  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      {isExpandable && (
        <button
          className='tw-border-0 tw-bg-transparent'
          onClick={(e) => {
            e.preventDefault();
            setIsExpanded(!isExpanded);
          }}>
          {isExpanded ? (
            <i className='bi bi-caret-down'></i>
          ) : (
            <i className='bi bi-caret-right'></i>
          )}
        </button>
      )}

      {!isExpanded ? (
        <>
          {listFiles && listFiles.map((filenameOrObj, index) => {
            const { storagePath: filename, downloadURL } = filenameOrObj;
            return <FileDownloadButton
              key={filename}
              filename={filename}
              downloadURL={downloadURL}
              // isDeletionMode={attachmentDeletionMode}
              // callbackAfterDelete={() => {
              //   row.receipts = row.receipts.filter(file => file !== filename)
              //   row.receiptsDeleted = [...(row.receiptsDeleted ?? []), filename]
              //   setAttachmentDeletionMode(false)
              //   updateLegList()
              // }}
              loginfo={loginfo}
            />;
          })}

          {!isReadOnly && (
            <FileUploadButton
              itemId={uploadButtonId}
              storageFolder={`${storageBaseFolder}/${userDetails.id}`}
              callbackUploadSuccess={callbackUploadSuccess!} // non-null because !isReadOnly
            />
          )}
        </>
      ) : (
        <>
          {!isReadOnly && (
            <FileUploadButton
              itemId={uploadButtonId}
              storageFolder={`${storageBaseFolder}/${userDetails.id}`}
              callbackUploadSuccess={callbackUploadSuccess!} // non-null because !isReadOnly
              isLargeButton={true}
            />
          )}

          <table className='
          [&>*>tr>*]:tw-border [&>*>tr>*]:tw-border-solid [&>*>tr>*]:tw-border-slate-400
          [&>*>tr>*]:tw-py-1 [&>*>tr>*]:tw-px-2'>
            <thead>
              <tr>
                <th>Filename</th>
                <th>Download</th>
                {!isReadOnly && handleDeleteFile && (
                  <th>Delete</th>
                )}
              </tr>
            </thead>
            <tbody>
              {listFiles && listFiles.length > 0 ? (
                listFiles.map((filenameOrObj, index) => {
                  const { storagePath: filename, downloadURL } = filenameOrObj;
                  let filename_simple = filename.split('/').pop()!; // split() never returns an empty array
                  const match = filename_simple.match(/\d{8}_\d{6} (.+)/);
                  if (match)
                    filename_simple = match[1];

                  return (
                    <tr key={index}>
                      <td>
                        {filename_simple}
                      </td>
                      <td>
                        <FileDownloadButton
                          filename={filename}
                          downloadURL={downloadURL}
                          isDeletionMode={false} // separate button for deleting
                          loginfo={loginfo}
                        />
                      </td>
                      {!isReadOnly && handleDeleteFile && (
                        <td>
                          <button className='tw-border-0 tw-p-0 tw-bg-transparent' onClick={(e) => {
                            e.preventDefault();
                            handleDeleteFile(filenameOrObj);
                          }}>
                            <i className='bi bi-trash'></i>
                          </button>
                        </td>
                      )}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={3} className='tw-italic'>No files</td>
                </tr>
              )}
            </tbody>
          </table>


        </>
      )}
    </>
  );
}
