import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppContext } from 'src/hooks/useAppContext';
import { GeneralExpenseType } from 'src/types/types_generalexpense';
import { InvoiceType } from 'src/types/types_invoices';
import { TourRequestType } from 'src/types/types_tourrequest';
import { formatNum } from 'src/util/util_formatnum';
import { arraySum } from 'src/util/util_misc';



interface RequestExpenseMiniTableProps {
  tourrequest: TourRequestType;
  show: boolean;
}

export function RequestExpenseMiniTable({
  tourrequest,
  show: showExpenses,
}: RequestExpenseMiniTableProps) {

  const { db, setDbError, userDetails } = useAppContext();


  const [reqInvoices, setReqInvoices] = useState<InvoiceType[] | null>(null);
  useEffect(() => {
    if (!showExpenses) return;
    if (!tourrequest.requestCode) return;
    onSnapshot(
      query(
        collection(db, 'invoices'),
        where('_isDeleted', '==', false),
        where('tripcode', '==', tourrequest.requestCode)
      ),
      (snapshot) => {
        console.log(`Retrieved ${snapshot.size} invoices`);
        setReqInvoices(snapshot.docs.map((docu) => ({ ...docu.data(), id: docu.id } as InvoiceType)));
      },
      (err) => setDbError(`Getting invoices for tourrequest ${tourrequest.id} ${tourrequest.requestCode}`, err));
  }, [db, setDbError, tourrequest.id, tourrequest.requestCode, showExpenses]);

  const [reqGeneralExpenses, setReqGeneralExpenses] = useState<GeneralExpenseType[] | null>(null);
  useEffect(() => {
    if (!showExpenses) return;
    if (!tourrequest.requestCode) return;
    onSnapshot(
      query(
        collection(db, 'generalexpenses'),
        where('_isDeleted', '==', false),
        where('requestCode', '==', tourrequest.requestCode)
      ),
      (snapshot) => {
        console.log(`Retrieved ${snapshot.size} general expenses`);
        setReqGeneralExpenses(snapshot.docs.map((docu) => ({ ...docu.data(), id: docu.id } as GeneralExpenseType)));
      },
      (err) => setDbError(`Getting generalexpenses for tourrequest ${tourrequest.id} ${tourrequest.requestCode}`, err));
  }, [db, setDbError, tourrequest.id, tourrequest.requestCode, showExpenses]);

  const expenseTable = useMemo(() => {
    if (!showExpenses || !reqInvoices || !reqGeneralExpenses) return null;
    const count = reqInvoices.length + reqGeneralExpenses.length;
    const total = arraySum(reqInvoices.map((invoice) => invoice.amount))
      + arraySum(reqGeneralExpenses.map((expense) => expense.totalAmount ?? 0));
    return (
      <table className='requestListExpenseTable'>
        <thead>
          <tr><th>Type</th><th>Amount</th><th>Desc</th><th>Link</th></tr>
        </thead>
        <tbody>
          {reqInvoices.map((invoice) => {
            return <tr key={invoice.id}>
              <td>invoice</td>
              <td className='numeric'>{formatNum(invoice.amount)}</td>
              <td>{invoice.payeeNameEn} - {invoice.servicePurchased}</td>
              <td><Link to={`/invoices/?requestCode=${tourrequest.requestCode}`}>link</Link></td>
            </tr>;
          })}
          {reqGeneralExpenses.map((genexp) => {
            return <tr key={genexp.id}>
              <td>gen. expense</td>
              <td className='numeric'>{formatNum(genexp.totalAmount)}</td>
              <td>{genexp.description}</td>
              <td><Link to={`/general-expenses/edit/${genexp.id}`}>link</Link></td>
            </tr>;
          })}
          {count === 0 && <tr><td colSpan={4}>No expenses</td></tr>}
        </tbody>
        <tfoot>
          <tr>
            <td>Total</td>
            <td className='numeric'>{formatNum(total)}</td>
            <td></td>
            <td></td>
          </tr>
        </tfoot>
      </table>
    );

  }, [showExpenses, reqInvoices, reqGeneralExpenses, tourrequest.requestCode]);


  return expenseTable;

}
