import { useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { ButtonTW } from 'src/components/Buttons/ButtonTW';
import { CheckboxSwitch } from 'src/components/Buttons/CheckboxSwitch';
import { useAppContext } from 'src/hooks/useAppContext';
import { userrole_isAdmin } from 'src/util/user_roles';
import { PageTourRequestStateType } from '../types_tourrequest_state';
import { RequestListTable } from './TableParts/RequestListTable';
import { RequestListTableRow } from './TableParts/RequestListTableRow';
import './tourrequestlist.css';
import { useSearchRequestList } from './useSearchRequestList';



interface TourRequestSearchProps {
  state: PageTourRequestStateType;
}

export function TourRequestSearch({ state }: TourRequestSearchProps) {

  const { db, userDetails } = useAppContext();

  const {
    showAllColumns, setShowAllColumns,
    showSalesInfo1, setShowSalesInfo1,
    showExpensesMiniTable, setShowExpensesMiniTable,
    expandPaymentsTable, setExpandPaymentsTable,
    travellersShownReqIdList, setTravellersShownReqIdList,

    // below are for search page
    searchTerms, setSearchTerms,
    searchResults, setSearchResults,
  } = state;


  const { currentlyTyping, waitingForSearchResults, onRefreshButtonClick } = useSearchRequestList(searchTerms, setSearchResults);

  const [shownRequestAggregatorPopup, setShownRequestAggregatorPopup] = useState<string | null>(null);


  const tablerows = useMemo(() => {
    //if (!isLoading || true) {
    if (!searchResults)
      return [];

    return searchResults.hits.map((tourrequest, index) => {
      return (
        <RequestListTableRow
          key={tourrequest.id}
          tourrequest={tourrequest}
          statusKey='all'
          showAllColumns={showAllColumns}
          showSalesInfo1={showSalesInfo1}
          showPayments={true}
          showExpensesMiniTable={showExpensesMiniTable}
          expandPaymentsTable={expandPaymentsTable}
          setExpandPaymentsTable={setExpandPaymentsTable}
          indexFirstItem={0}
          index={index}
          travellersShownReqIdList={travellersShownReqIdList}
          setTravellersShownReqIdList={setTravellersShownReqIdList}
          shownRequestAggregatorPopup={shownRequestAggregatorPopup}
          setShownRequestAggregatorPopup={setShownRequestAggregatorPopup}
        />
      );
    });
  }, [searchResults, showAllColumns, showSalesInfo1, showExpensesMiniTable, travellersShownReqIdList, setTravellersShownReqIdList, shownRequestAggregatorPopup, expandPaymentsTable, setExpandPaymentsTable]);


  // *** all hooks above this line ***



  return (
    <div className='container-fluid mx-3'>
      <Helmet><title>Search Request List</title></Helmet>

      <Link to='/requests/'>&lt;&lt;Back to Request List</Link>

      <h2 className='tw-my-6'>Search request list</h2>

      <div className='tw-flex tw-my-4'>

        <div className='tw-pe-4 tw-pt-2'>Search text:</div>
        <div style={{ width: '25em' }}>
          <Form.Control type='text' value={searchTerms} className={currentlyTyping ? 'currentlyTyping' : ''} onChange={(e) => {
            setSearchTerms(e.target.value);
          }} />
        </div>
        <div>
          <ButtonTW variant='blue_outline' className='tw-ml-3' onClick={() => {
            onRefreshButtonClick();
          }}><i className='bi bi-arrow-repeat'></i></ButtonTW>
        </div>

      </div>

      <div className='mb-2'>
        {searchResults ? (
          searchResults.hits.length >= 20 ? (
            <b>20+ results found, top 20 results displayed</b>
          ) : (
            <b>{searchResults.hits.length} results found</b>
          )
        ) : (
          <b>Enter some search terms</b>
        )}
      </div>

      <div className='d-flex'>
        <div className='rounded-border-box'>
          Note that the search engine allows for typos and approximate spellings, so it may return results that do not match exactly the search terms.
        </div>
      </div>

      <div className='d-flex mb-2'>

        <CheckboxSwitch id='switchShowAllColumns' label='Show all columns' className='mt-2 ms-4' checked={showAllColumns} onChange={(e) => {
          setShowAllColumns(e.target.checked);
        }} />

        {userrole_isAdmin(userDetails.roles) && (
          <>
            <CheckboxSwitch id='switchShowSalesInfo1' label='Admin: Show Sales Info 1' className='mt-2 ms-4' checked={showSalesInfo1} onChange={(e) => {
              setShowSalesInfo1(e.target.checked);
            }} />

            <CheckboxSwitch id='switchShowExpensesMiniTable' label='Admin: Show expenses mini table' className='mt-2 ms-4' checked={showExpensesMiniTable} onChange={(e) => {
              setShowExpensesMiniTable(e.target.checked);
            }} />
          </>
        )}

      </div>


      <RequestListTable
        tablerows={tablerows}
        showAllColumns={showAllColumns}
        showSalesInfo1={showSalesInfo1}
        showPayments={true}
        isLoadingTableRows={waitingForSearchResults}
        columnFilterAppliedData={null}
        columnFilterCacheData={null}
        allowEditingTourStatus={false}
      />


    </div>
  );
}
