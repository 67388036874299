import { useState } from 'react';
import { AddButton } from 'src/components/Buttons/AddButton';
import { DeleteButton } from 'src/components/Buttons/DeleteButton';
import { MoveUpDownButtons } from 'src/components/Buttons/MoveUpDownButtons';
import { EditableFieldExpensesAutosave } from 'src/components/EditableField/EditableFieldExpensesAutosave';
import { EditableFieldExpensesAutosaveDatepicker } from 'src/components/EditableFieldDatepicker/EditableFieldExpensesAutosaveDatepicker';
import { FileDownloadButton } from 'src/components/FileDownloadUpload/FileDownloadButton';
import { FileUploadButton } from 'src/components/FileDownloadUpload/FileUploadButton';
import { useAppContext } from 'src/hooks/useAppContext';
import { ExpenseOtherExpense, ExpenseSheetType } from 'src/types/types_expensesheet';
import { addDaysIso, getSpanDaysExactIso, iso_from_jst0, jst0_from_iso } from 'src/util/datetools';
import { formatNum } from 'src/util/util_formatnum';
import { compare } from 'src/util/util_misc';
import { nano_id } from 'src/util/util_nano_id';
import { AutosaveSheetType } from '../ExpenseSheet';


interface F_OtherExpensesProps {
  sheet: ExpenseSheetType;
  isReadOnly: boolean;
  editedCell: string | null;
  setEditedCell: (cellid: string | null) => void;
  autosaveNewStep: AutosaveSheetType;
}

export function F_OtherExpenses({
  sheet,
  isReadOnly,
  editedCell,
  setEditedCell,
  autosaveNewStep,
}: F_OtherExpensesProps) {

  const { userDetails } = useAppContext();

  const [attachmentDeletionMode, setAttachmentDeletionMode] = useState(false);

  return (
    <>
      <h5 className='my-4'>Other expenses</h5>

      <div>
        <table className='table mb-1 inputtingTable' style={{ width: `${4 + 8 * 7 + 16 + 4}em` }}>
          <colgroup>
            <col style={{ width: '4em' }} />
            <col style={{ width: '8em' }} />
            <col style={{ width: '8em' }} />
            <col style={{ width: '8em' }} />
            <col style={{ width: '8em' }} />
            <col style={{ width: '8em' }} />
            <col style={{ width: '8em' }} />
            <col style={{ width: '8em' }} />
            <col style={{ width: '16em' }} />
            <col style={{ width: '4em' }} />
          </colgroup>
          <thead>
            <tr className='row-header'>
              <th></th>
              <th>Day №</th>
              <th>Date</th>
              <th>Name</th>
              <th>Unit price</th>
              <th>Qty</th>
              <th>Total</th>
              <th style={{ whiteSpace: 'nowrap' }}>
                Receipt
                {!isReadOnly && (
                  <button style={{ 'border': 'none', 'background': 'transparent' }} onClick={(e) => {
                    setAttachmentDeletionMode(!attachmentDeletionMode);
                  }}><i className={`bi ${attachmentDeletionMode ? 'bi-x-square-fill' : 'bi-x-square'}`}></i></button>
                )}
              </th>
              <th>Memo</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {sheet.otherExpenses.filter((row) => !row.isDeleted).map((row, index) => {
              const rowInfo = {
                sheet,
                rowid: row.id,
                tableid: 'otherExpenses' as keyof ExpenseSheetType,
                isClickableToEdit: !isReadOnly,
                editedCell: editedCell,
                setEditedCell: setEditedCell,
                autosaveNewStep,
              };

              return (
                <tr key={row.id}>
                  <td>
                    {/* <div draggable style={{ backgroundColor: '', width: '2rem', display: 'inline-block', textAlign: 'center' }}><i className='bi bi-arrow-down-up'></i></div> */}
                  </td>
                  <td>
                    <EditableFieldExpensesAutosave
                      fieldname='dayNumber'
                      currentValue={row.dayNumber}
                      validationType='number'
                      nextField='expenseName'

                      customSaveCallback={(dbvalue: number) => {
                        if (row.dayNumber === dbvalue)
                          return null; // no change

                        const otherExpensesNew = sheet.otherExpenses.map((item) => {
                          if (item.id === row.id) {
                            const rowNew: ExpenseOtherExpense = {
                              ...item,
                              dayNumber: dbvalue,
                              dateiso: sheet.dateisoTourStart ? addDaysIso(sheet.dateisoTourStart, dbvalue - 1) : '',
                            };
                            return rowNew;
                          } else {
                            return item;
                          }
                        });

                        otherExpensesNew.sort((a, b) => compare(a.dayNumber, b.dayNumber));
                        const updateObj: Partial<ExpenseSheetType> = {
                          otherExpenses: otherExpensesNew,
                        };

                        return updateObj;
                      }}

                      {...rowInfo}
                      sUndoWall={'u'}
                    />
                  </td>
                  <td style={{ whiteSpace: 'nowrap', minWidth: '6.5em' }}>
                    <EditableFieldExpensesAutosaveDatepicker
                      fieldname='date'
                      currentValue={row.dateiso ? jst0_from_iso(row.dateiso) : null}

                      customSaveCallback={(date_jst0) => {
                        const dateiso = date_jst0 ? iso_from_jst0(date_jst0) : '';

                        const otherExpensesNew = sheet.otherExpenses.map((item) => {
                          if (item.id === row.id) {
                            const rowNew: ExpenseOtherExpense = {
                              ...item,
                              dateiso,
                              dayNumber: sheet.dateisoTourStart ? (getSpanDaysExactIso(sheet.dateisoTourStart, dateiso) + 1) : null,
                            };
                            return rowNew;
                          } else {
                            return item;
                          }
                        });

                        otherExpensesNew.sort((a, b) => compare(a.dayNumber, b.dayNumber));
                        const updateObj: Partial<ExpenseSheetType> = {
                          otherExpenses: otherExpensesNew,
                        };

                        return updateObj;
                      }}
                      {...rowInfo}
                      sUndoWall={'u'}
                    />
                  </td>
                  <td>
                    <EditableFieldExpensesAutosave
                      fieldname='expenseName'
                      currentValue={row.expenseName}
                      validationType=''
                      previousField='dayNumber'
                      nextField='unitPrice'
                      {...rowInfo}
                      sUndoWall={'u'}
                    />
                  </td>
                  <td>
                    <EditableFieldExpensesAutosave
                      fieldname='unitPrice'
                      currentValue={row.unitPrice}
                      validationType='number'
                      previousField='expenseName'
                      nextField='quantity'
                      {...rowInfo}
                      sUndoWall={'u'}
                    />
                  </td>
                  <td>
                    <EditableFieldExpensesAutosave
                      fieldname='quantity'
                      currentValue={row.quantity}
                      validationType='number'
                      previousField='unitPrice'
                      nextField='totalAmount'
                      {...rowInfo}
                      sUndoWall={'u'}
                    />
                  </td>
                  <td>
                    <EditableFieldExpensesAutosave
                      fieldname='totalAmount'
                      currentValue={(row.unitPrice ?? 0) * row.quantity} // never shows as blank. will have zero if unit cost is null (blank)
                      validationType='number'
                      previousField='quantity'
                      nextField='memorandum'

                      customSaveCallback={(dbvalue) => {
                        if (dbvalue === (row.unitPrice ?? 0) * row.quantity) {
                          // if value wasn't modified, don't overwrite unit price and quantity
                          return null;
                        }

                        const otherExpensesNew = sheet.otherExpenses.map((item) => {
                          if (item.id === row.id) {
                            const rowNew: ExpenseOtherExpense = {
                              ...item,
                              unitPrice: dbvalue,
                              quantity: 1,
                            };
                            return rowNew;
                          } else {
                            return item;
                          }
                        });

                        const updateObj: Partial<ExpenseSheetType> = {
                          otherExpenses: otherExpensesNew,
                        };

                        return updateObj;
                      }}

                      {...rowInfo}
                      sUndoWall={'u'}
                    />

                    {/* {
                      (row.unitPrice !== '' && row.quantity !== '')
                        ? formatNum(row.unitPrice * row.quantity)
                        : ''
                    } */}

                  </td>
                  <td>
                    {row.receipts && row.receipts.map((filenameOrObj, index) => {
                      const { storagePath: filename, downloadURL } = filenameOrObj;
                      return <FileDownloadButton
                        key={filename}
                        filename={filename}
                        downloadURL={downloadURL}
                        isDeletionMode={attachmentDeletionMode}
                        callbackAfterDelete={() => {
                          const receiptsNew = row.receipts.filter((file) => {
                            if (typeof file === 'string')
                              return file !== filename;
                            else
                              return file.storagePath !== filename;
                          });
                          const receiptsDeletedNew = [...(row.receiptsDeleted ?? []), filenameOrObj];
                          setAttachmentDeletionMode(false);

                          const rowNew: ExpenseOtherExpense = {
                            ...row,
                            receipts: receiptsNew,
                            receiptsDeleted: receiptsDeletedNew,
                          };

                          const otherExpensesNew = sheet.otherExpenses.map((item) => {
                            if (item.id === row.id) {
                              return rowNew;
                            } else {
                              return item;
                            }
                          });

                          const updateObj: Partial<ExpenseSheetType> = {
                            otherExpenses: otherExpensesNew,
                          };

                          autosaveNewStep('Other expenses: delete receipt', updateObj, 'u');
                        }}
                        loginfo={`other expenses on sheet ${sheet.id}`}
                      />;
                    })}

                    {!isReadOnly && (
                      <FileUploadButton
                        itemId={`${rowInfo.tableid}_${row.id}`}
                        storageFolder={`receipts/${userDetails.id}`}
                        callbackUploadSuccess={(uploadedFilePathsAndURLs) => {
                          const receiptsNew = [...row.receipts, ...uploadedFilePathsAndURLs];

                          const otherExpensesNew = sheet.otherExpenses.map((item) => {
                            if (item.id === row.id) {
                              const newItem: ExpenseOtherExpense = {
                                ...item,
                                receipts: receiptsNew,
                              };
                              return newItem;
                            } else {
                              return item;
                            }
                          });

                          const updateObj: Partial<ExpenseSheetType> = {
                            otherExpenses: otherExpensesNew,
                          };

                          autosaveNewStep('Other expenses: upload receipt', updateObj, 'u');
                        }}
                      />
                    )}
                  </td>
                  <td>
                    <EditableFieldExpensesAutosave
                      fieldname='memorandum'
                      currentValue={row.memorandum}
                      validationType=''
                      previousField='totalAmount'
                      {...rowInfo}
                      sUndoWall={'u'}
                    />
                  </td>
                  <td>
                    {!isReadOnly && (
                      <div className='d-flex'>
                        <DeleteButton onClick={() => {
                          if (!window.confirm('Delete row?'))
                            return;

                          const otherExpensesNew = sheet.otherExpenses.map((item) => {
                            if (item.id === row.id) {
                              const newItem: ExpenseOtherExpense = {
                                ...item,
                                isDeleted: true,
                              };
                              return newItem;
                            } else {
                              return item;
                            }
                          });

                          const updateObj: Partial<ExpenseSheetType> = {
                            otherExpenses: otherExpensesNew,
                          };

                          autosaveNewStep('Other expenses: delete row', updateObj, 'u');
                        }} />

                        <MoveUpDownButtons index={index} array={sheet['otherExpenses']} setArray={(array) => {
                          const updateObj: Partial<ExpenseSheetType> = {
                            otherExpenses: array,
                          };
                          autosaveNewStep('Other expenses: reorder rows', updateObj, 'u');
                        }} />

                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>Total</td>
              <td>{formatNum(sheet.calc.totalOtherExpenses)}</td>
              <td></td>
              <td></td>
              <td>
                {!isReadOnly && (
                  <AddButton onClick={() => {
                    const newRow: ExpenseOtherExpense = {
                      id: nano_id(),
                      dayNumber: null,
                      dateiso: '',
                      expenseName: '',
                      unitPrice: null,
                      quantity: 1,
                      memorandum: '',
                      receipts: [],
                      isDeleted: false,
                    };

                    const otherExpensesNew: ExpenseOtherExpense[] = [...sheet.otherExpenses, newRow];

                    const updateObj: Partial<ExpenseSheetType> = {
                      otherExpenses: otherExpensesNew,
                    };

                    autosaveNewStep('Other expenses: add row', updateObj, 'u');
                  }} />
                )}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </>
  );
}
