import { useMemo } from 'react';
import { GuideCalendarType, TourRequestTypeWithDates } from 'src/types/types_tourrequest';
import { getSpanDaysExact, utc0_from_iso } from 'src/util/datetools';
import { UserSimpleCalendarGuideType } from './useGuideList';
import { getTourrequestColors } from './util_tourcalendar_colors';



function getJsxForCell(item: CellContentsType) {
  let jsx: JSX.Element;
  if (item.cellType === 'tour') {
    let listDesigners: string;
    const req = item.requestObj;
    if (req.usersDesigners?.length) {
      listDesigners = req.usersDesigners.map((u) => u.name).join(', ');
    } else {
      // can happen if (1) user manually deletes all designers, or (2) for very old requests created by Maya (no Portal account) back on Kintone
      console.log('no usersDesigners');
      listDesigners = `(${req.userCreatedName})`;
    }
    const firstLine = req.travellerName === 'Explore' ? req.requestCode : `${req.travellerName} [${req.requestCode}]`;
    jsx = <div className='guideCalendarRectangle'>
      <div>{firstLine}</div>
      <div>{req.agencyOrPlatform}</div>
      <div>{listDesigners}</div>
    </div>;
  } else if (item.cellType === 'dayoff') {
    jsx = <>{item.identifier}</>;
  } else if (item.cellType === 'officework') {
    jsx = <>{item.identifier}</>;
  } else if (item.cellType === 'calendarnote') {
    jsx = <>{item.identifier}</>;
  } else {
    // ??
    throw new Error('invalid item');
  }
  return jsx;
}


// used in lookup array. a single day has an array of these.
export type CellContentsType = ({
  cellType: 'tour';
  requestObj: TourRequestTypeWithDates;
} | {
  cellType: 'dayoff' | 'officework' | 'calendarnote';
  requestObj: null;
}) & {
  identifier: string; // used to determine if successive cells can be merged
  backgroundColor: string;
  textColor: string;
  dateiso: string;
  dateutc: Date;
  rectangle: RectangularBlockType;
  guidingHours: number | null;
  guidingAdditionalText: string | null;
  guidingAmPm: 'am_only' | 'pm_only' | '' | null;
};

// used in rectangleList array
export type RectangularBlockType = {
  cellType: 'tour' | 'dayoff' | 'officework' | 'calendarnote';
  identifier: string; // common to all cells that were merged into this rectangle
  dayIndex: number; // 0-based
  length: number;
  cellContentJsx: JSX.Element;
  backgroundColor: string;
  textColor: string;
  cells: CellContentsType[];
};

export type GuideCalendarCellDataType = {
  lookup: Map<string, Map<number, CellContentsType[]>>;
  allRectangles: Map<string, Array<RectangularBlockType>>;
};



export function useGuideCalendarCellData(
  dateutcCalendarStart: Date,
  dateutcCalendarEnd: Date,
  guideCalendars: GuideCalendarType[] | undefined,
  requests: TourRequestTypeWithDates[] | undefined,
  usedGuides: UserSimpleCalendarGuideType[] | undefined,
): GuideCalendarCellDataType | undefined {


  // lookup contains a 2d grid of the calendar, for each guide and each days, will list the contents
  // row(iGuide)(0-based) => dayNum(1-based) => contents[]
  const guideCalendarCellData: GuideCalendarCellDataType | undefined = useMemo(() => {

    if (!guideCalendars || !requests || !usedGuides) {
      // still loading
      return undefined;
    }

    console.log('Run layout computation on guide calendar grid');

    const lookup = new Map<string, Map<number, CellContentsType[]>>();
    const allRectangles = new Map<string, Array<RectangularBlockType>>();

    usedGuides.forEach((guide, iGuide) => {

      const days = new Map<number, CellContentsType[]>();

      // 1. get all the tour request cells
      for (const request of requests) {
        if (!request.calendarDays)
          continue;
        for (const [dateiso, calendarDay] of Object.entries(request.calendarDays)) {
          if (!calendarDay.guides)
            continue;
          for (const requestGuide of calendarDay.guides) {
            if (requestGuide.id !== guide.id)
              continue;

            if (!dateiso.match(/^\d{4}-\d{2}-\d{2}$/))
              throw new Error(`invalid date key format in calendarDays on request [${request.id}]`);
            const dateutc = utc0_from_iso(dateiso);
            if (dateutc > dateutcCalendarEnd)
              // this day is not shown on calendar
              continue;

            const calendarDayIndex = getSpanDaysExact(dateutcCalendarStart, dateutc); // 0-based
            let thisDay = days.get(calendarDayIndex);
            if (!thisDay) {
              thisDay = [];
              days.set(calendarDayIndex, thisDay);
            }
            // const content = `${request.travellerName} [${request.requestCode}]`
            const tourColors = getTourrequestColors(request);
            const dayContents: CellContentsType = {
              cellType: 'tour',
              // text: content,
              identifier: `req:${request.id}`,
              backgroundColor: tourColors.mainBg,
              textColor: tourColors.mainText,
              dateiso,
              dateutc,
              requestObj: request,
              rectangle: null!, // will be set below
              guidingHours: requestGuide.hours ?? null,
              guidingAdditionalText: calendarDay.additionalText ?? null,
              guidingAmPm: requestGuide.am_pm_only ?? null,
            };
            thisDay.push(dayContents);
          }
        }
      }

      // 2. get all the 'days off' / 'office work' / 'calendar notes' cells
      const guideCalendar = guideCalendars.find((gc) => gc.id === guide.id);
      if (guideCalendar) {
        if (guideCalendar.daysOff) {
          for (const [dateiso, daysOff] of Object.entries(guideCalendar.daysOff)) {
            if (!dateiso.match(/^\d{4}-\d{2}-\d{2}$/))
              // old format
              continue;
            if (daysOff._isDeleted)
              continue;
            if (daysOff.dateiso !== dateiso)
              throw new Error(`inconsistent date on days off guideid=${guide.id} dateiso=${dateiso}`);
            const dateutc = utc0_from_iso(dateiso);
            if (dateutc > dateutcCalendarEnd)
              // this day is not shown on calendar
              continue;

            const dayIndex = getSpanDaysExact(dateutcCalendarStart, dateutc); // 0-based
            let thisDay = days.get(dayIndex);
            if (!thisDay) {
              thisDay = [];
              days.set(dayIndex, thisDay);
            }
            const dayContents: CellContentsType = {
              cellType: 'dayoff',
              identifier: `${guide.name} off`,
              backgroundColor: 'rgb(98, 106, 114)', // bootstrap-secondary
              textColor: 'white',
              dateiso,
              dateutc,
              requestObj: null,
              rectangle: null!, // will be set below
              guidingHours: null,
              guidingAdditionalText: null,
              guidingAmPm: null,
            };
            thisDay.push(dayContents);
          }
        }

        if (guideCalendar.officeWork) {
          for (const [dateiso, officeWk] of Object.entries(guideCalendar.officeWork)) {
            if (!dateiso.match(/^\d{4}-\d{2}-\d{2}$/))
              throw new Error('invalid date on office work');
            if (officeWk._isDeleted)
              continue;
            if (officeWk.dateiso !== dateiso)
              throw new Error(`inconsistent date on office work guideid=${guide.id} dateiso=${dateiso}`);
            const dateutc = utc0_from_iso(dateiso);
            if (dateutc > dateutcCalendarEnd)
              // this day is not shown on calendar
              continue;


            const dayIndex = getSpanDaysExact(dateutcCalendarStart, dateutc); // 0-based
            let thisDay = days.get(dayIndex);
            if (!thisDay) {
              thisDay = [];
              days.set(dayIndex, thisDay);
            }
            const dayContents: CellContentsType = {
              cellType: 'officework',
              identifier: `${officeWk.workContent}`,
              backgroundColor: 'rgb(255, 198, 29)', // bootstrap-warning
              textColor: 'black',
              dateiso,
              dateutc,
              requestObj: null,
              rectangle: null!, // will be set below
              guidingHours: officeWk.workHours,
              guidingAdditionalText: null,
              guidingAmPm: null,
            };
            thisDay.push(dayContents);
          }
        }

        if (guideCalendar.calendarNotes) {
          for (const [dateiso, calNote] of Object.entries(guideCalendar.calendarNotes)) {
            if (!dateiso.match(/^\d{4}-\d{2}-\d{2}$/))
              throw new Error('invalid date on calendar note');
            if (calNote._isDeleted)
              continue;
            if (calNote.dateiso !== dateiso)
              throw new Error(`inconsistent date on calendar note guideid=${guide.id} dateiso=${dateiso}`);
            const dateutc = utc0_from_iso(dateiso);
            if (dateutc > dateutcCalendarEnd)
              // this day is not shown on calendar
              continue;


            const dayIndex = getSpanDaysExact(dateutcCalendarStart, dateutc); // 0-based
            let thisDay = days.get(dayIndex);
            if (!thisDay) {
              thisDay = [];
              days.set(dayIndex, thisDay);
            }
            const dayContents: CellContentsType = {
              cellType: 'calendarnote',
              identifier: `${calNote.calendarNoteContent}`,
              backgroundColor: '#ddd', //'#debaf0',
              textColor: 'black',
              dateiso,
              dateutc,
              requestObj: null,
              rectangle: null!, // will be set below
              guidingHours: null,
              guidingAdditionalText: null,
              guidingAmPm: null,
            };
            thisDay.push(dayContents);
          }
        }
      }

      // note: at this point, `lookup` is not quite complete yet, as we still need to assign item.rectangle below

      // generate rectangleList from lookup. a rectangle (=rectangular block) can span multiple days
      const rectangleList = new Array<RectangularBlockType>();

      [...days.keys()].sort((a, b) => a - b).forEach((calendarDayIndex) => { // calendarDayIndex is 0-based
        // calendarDayIndex does not take every value, only values where there is something on the day
        const items: CellContentsType[] = days.get(calendarDayIndex)!;
        const prevDayItems: CellContentsType[] = days.get(calendarDayIndex - 1) ?? [];

        for (const item of items) {

          let rectangle: RectangularBlockType;
          const prevItem = prevDayItems.find((x) => x.identifier === item.identifier);
          if (prevItem) {
            // rectangle covers previous adjacent cell AND identifier matches.
            // extend the rectangle one cell further
            rectangle = prevItem.rectangle;
            rectangle.length++;
            rectangle.cells.push(item);
          } else {
            rectangle = {
              cellType: item.cellType,
              identifier: item.identifier,
              dayIndex: calendarDayIndex, // 0-based
              length: 1,
              cellContentJsx: getJsxForCell(item),
              backgroundColor: item.backgroundColor,
              textColor: item.textColor,
              cells: [item],
            };
            rectangleList.push(rectangle);
          }
          // save it for later (/!\ here we mutate the contents of `lookup`)
          item.rectangle = rectangle;
        }

      }); // each day

      lookup.set(guide.id, days);
      allRectangles.set(guide.id, rectangleList);
    }); // each guide

    return { lookup, allRectangles };

  }, [dateutcCalendarStart, dateutcCalendarEnd, guideCalendars, requests, usedGuides]); // useMemo

  return guideCalendarCellData;

}

export function guideDayHasConflict(items: CellContentsType[] | undefined):
  | ''
  | 'CONFLICT'
  | 'MULTIPLE' { // MULTIPLE means there is an AM and PM job, but they are not in conflict

  if (!items)
    return '';

  const calendarItems = items.filter((item) => item.cellType !== 'calendarnote');

  if (calendarItems.length <= 1)
    // max 1 job today
    return '';

  if (calendarItems.length === 2) {
    // have 1 job in the morning and 1 job in the afternoon?
    if (calendarItems[0].guidingAmPm === 'am_only' && calendarItems[1].guidingAmPm === 'pm_only')
      return 'MULTIPLE';
    if (calendarItems[0].guidingAmPm === 'pm_only' && calendarItems[1].guidingAmPm === 'am_only')
      return 'MULTIPLE';
  }

  return 'CONFLICT';
}
