import { useEffect, useRef } from 'react';
import { ServiceTripQuotationRowType } from 'src/types/types_tripquotations';
import { formatNum } from 'src/util/util_formatnum';
import { ServicePriceCatalogType, ServicePriceItemType } from '../../types/types_supplierprices';

interface ServiceSearchProps {
  services: ServicePriceCatalogType[];
  serviceRowToMatch: ServiceTripQuotationRowType | undefined;
  serviceSearchQuery: string;
  setServiceSearchQuery: (query: string) => void;
  selectedService: ServicePriceCatalogType | undefined;
  setSelectedService: (service: ServicePriceCatalogType) => void;
  selectedServiceItem: ServicePriceItemType | undefined;
  setSelectedServiceItem: (serviceItem: ServicePriceItemType) => void;
  shouldSaveMapping: boolean;
  setShouldSaveMapping: (shouldSaveMapping: boolean) => void;
  automatchOnDetails: boolean;
  setAutomatchOnDetails: (automatchOnDetails: boolean) => void;
}

export function ServiceSearch({
  services,
  serviceRowToMatch,
  serviceSearchQuery,
  setServiceSearchQuery,
  selectedService,
  setSelectedService,
  setSelectedServiceItem,
  selectedServiceItem,
  shouldSaveMapping,
  setShouldSaveMapping,
  automatchOnDetails,
  setAutomatchOnDetails,
}: ServiceSearchProps) {

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    inputRef?.current?.focus();
  }, [inputRef]);

  const numOfPax = serviceRowToMatch?.numOfPax;

  return (
    <div className='tw-w-[70vw]'>
      {serviceRowToMatch && (
        <>
          <div className='tw-p-1'>
            <span className='tw-font-bold tw-mr-1'>
              Source service:
            </span>
            {serviceRowToMatch.sourceServiceName}
          </div>
          <div className='tw-p-1'>
            <span className='tw-font-bold tw-mr-1'>
              Details:
            </span>
            {serviceRowToMatch.serviceDetails}
          </div>
          <div className='tw-p-1'>
            <span className='tw-font-bold tw-mr-1'>
              Pax for this service:
            </span>
            {serviceRowToMatch.numOfPax}
          </div>
          <div className='tw-my-2'>
            <input
              type='search'
              ref={inputRef}
              defaultValue={serviceSearchQuery}
              onChange={(e) => {
                setServiceSearchQuery(e.target.value);
              }} />
            <span className='tw-ml-1'>
              {services.length} result(s)
            </span>
          </div>
          <div className='tw-h-[400px] tw-overflow-scroll'>
            <table className='
                  [&>*>tr>*]:tw-border [&>*>tr>*]:tw-border-solid [&>*>tr>*]:tw-border-slate-400
                  [&>*>tr>*]:tw-py-1 [&>*>tr>*]:tw-px-2'>
              <thead>
                <tr>
                  <th className='tw-bg-slate-200'>Service name</th>
                  <th className='tw-bg-slate-200'>Service Item</th>
                  <th className='tw-bg-slate-200'>Season</th>
                  <th className='tw-bg-slate-200'>Age</th>
                  <th className={numOfPax === 2 ? 'tw-bg-slate-300' : 'tw-bg-slate-200'}>Price per person for 2 pax</th>
                  <th className={numOfPax === 3 ? 'tw-bg-slate-300' : 'tw-bg-slate-200'}>Price per person for 3 pax</th>
                  <th className={(numOfPax && numOfPax > 3) ? 'tw-bg-slate-300' : 'tw-bg-slate-200'}>Price per person for 4 pax</th>
                </tr>
              </thead>
              {services.map((service) =>
                service.priceItems.map((priceItem) => {
                  const isSelected = selectedServiceItem && selectedServiceItem?.id === priceItem.id;

                  // not selected, no hover: bg-white
                  // not selected, hover:    bg-blue-400/20
                  // selected,     no hover: bg-blue-400/50
                  // selected,     hover:    bg-blue-400/60
                  return <tbody
                    key={`${service.serviceName}${priceItem.priceItemName}`}
                    className={`${isSelected ? '[&>tr>td]:tw-bg-blue-400/50 hover:tw-bg-blue-400/60' : '[&>tr>td]:hover:tw-bg-blue-400/20'} tw-cursor-pointer`}
                    onClick={() => {
                      setSelectedService(service);
                      setSelectedServiceItem(priceItem);
                    }}
                  >
                    {priceItem.seasons.map((priceItemSeason, seasonIndex) =>
                      priceItemSeason.ages.map(priceItemSeasonAge => {


                        return (
                          <tr key={`${service.serviceName}${priceItem.priceItemName}${priceItemSeasonAge.ageName}`}
                          >
                            {seasonIndex === 0 && <td rowSpan={priceItem.seasons.length}>{service.serviceName}</td>}
                            {seasonIndex === 0 && <td rowSpan={priceItem.seasons.length}>{priceItem.priceItemName}</td>}
                            <td>{priceItemSeason.seasonName}</td>
                            <td>{priceItemSeasonAge.ageName}</td>
                            <td className={numOfPax === 2 ? 'tw-bg-slate-100' : ''}>{formatNum(priceItemSeasonAge.pricePerPerson2pax)}</td>
                            <td className={numOfPax === 3 ? 'tw-bg-slate-100' : ''}>{formatNum(priceItemSeasonAge.pricePerPerson3pax)}</td>
                            <td className={(numOfPax && numOfPax > 3) ? 'tw-bg-slate-100' : ''}>{formatNum(priceItemSeasonAge.pricePerPerson4pax)}</td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>;
                }
                ))
              }
            </table>
          </div>
          <div className='tw-mt-4'>
            <label title='Next time you import a quotation request, these values will be automatically selected if the source is the same'>
              <input type='checkbox' className='tw-mr-1' checked={shouldSaveMapping} onChange={(e) => setShouldSaveMapping(e.target.checked)} />
              Use this selection for auto-matching
            </label>
          </div>
          <div className='tw-min-h-1'>
            {shouldSaveMapping && (
              <>
                <div>
                  <label>
                    <input type='checkbox' className='tw-mr-1' checked={automatchOnDetails} onChange={(e) => setAutomatchOnDetails(e.target.checked)} />
                    Include service details in auto-matching
                  </label>
                </div>
                <table className='
                      [&>*>tr>*]:tw-border [&>*>tr>*]:tw-border-solid [&>*>tr>*]:tw-border-slate-400
                      [&>*>tr>*]:tw-py-1 [&>*>tr>*]:tw-px-2 tw-my-2'>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Audley quotation</th>
                      <th>Price database</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Service name</th>
                      <td>{serviceRowToMatch.sourceServiceName}</td>
                      <td rowSpan={automatchOnDetails ? 2 : 1}>{selectedService?.serviceName} {selectedServiceItem?.priceItemName}</td>
                    </tr>
                    {automatchOnDetails && (
                      <tr>
                        <th>Service details</th>
                        <td>{serviceRowToMatch.serviceDetails}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}
