import { httpsCallable } from 'firebase/functions';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ButtonTW } from 'src/components/Buttons/ButtonTW';
import { useAppContext } from 'src/hooks/useAppContext';
import { EitherLegType, NonTransportationLegType, TransportationLegType } from 'src/types/types_commute';
import { katakanaToHiragana } from 'src/util/kana_conversion';
import { nano_id } from 'src/util/util_nano_id';
import { ModalTripInputNonTransportationTable } from './ModalTripInputNonTransportationTable';
import { ModalTripInputTransportationTable } from './ModalTripInputTransportationTable';
import './modaltripinput.css';



export type ModalTripInputActionType = {
  category: string;
  sIsoDate: string;
  legList: EitherLegType[] | undefined;
  saveLegList: (legList: EitherLegType[] | null) => void;
};

export type HttpsCallableInputType = {
  text: string;
};

export type HttpsCallableResultType = {
  data: any;
};

export type HttpsCallableFunction = (searchString: HttpsCallableInputType) => Promise<HttpsCallableResultType>;

export type HttpsCallableResultHandler = (result: HttpsCallableResultType) => any[] | null;

export type SuggestableFieldType = 'stationFrom' | 'stationTo' | 'trainLine';

export type SuggestionListType = { label: string }[] | 'loading' | 'no suggestions';

interface ModalTripInputProps {
  modalAction: ModalTripInputActionType | null;
  setModalAction: (modalAction: ModalTripInputActionType | null) => void;
}

export function ModalTripInput({
  modalAction,
  setModalAction,
}: ModalTripInputProps) {

  const { userDetails, db, setDbError, cloudFunctions, _lang } = useAppContext();


  const category = modalAction?.category;

  const isCommute = category === 'commuteToWork' || category === 'commuteFromWork';
  const isOtherTransportation = category === 'otherTransportationExpenses';
  const isTransportation = isCommute || isOtherTransportation;
  const isNonTransportation = category === 'otherNonTransportationExpenses';

  const title = isCommute ? 'Edit commute'
    : isOtherTransportation ? 'Edit transportation'
      : isNonTransportation ? 'Edit expenses'
        : '';


  const stationSuggestion = httpsCallable<HttpsCallableInputType, HttpsCallableResultType>(cloudFunctions, 'stationSuggestion');
  const railSearch = httpsCallable<HttpsCallableInputType, HttpsCallableResultType>(cloudFunctions, 'railSearch');

  const [suggestionList, setSuggestionList] = useState<SuggestionListType | null>(null);
  const [suggestionField, setSuggestionField] = useState<{ rowid: string; fieldname: string } | null>(null);

  const isReadOnly = false;

  const [editedCell, setEditedCell] = useState<string | null>(null);
  const [legList, setLegList] = useState<EitherLegType[]>([]);
  const [attachmentDeletionMode, setAttachmentDeletionMode] = useState(false);


  const updateLegList = () => setLegList([...legList]); // TODO: refactor to stop mutating state

  useEffect(() => {
    if (!modalAction) {
      setLegList([]);
      return;
    }

    if (!modalAction.legList || !modalAction.legList.length) {
      // initialize with default 1 row
      if (isTransportation) {
        setLegList(
          [
            {
              id: nano_id(),
              modeOfTransportation: null,
              trainLine: '',
              stationFrom: '',
              stationTo: '',
              legCost: null,
              memorandum: '',
            },
          ]
        );
      } else {
        setLegList(
          [
            {
              id: nano_id(),
              name: '',
              cost: null,
              receipts: [],
              memorandum: '',
            },
          ]
        );
      }
    } else {
      setLegList([...modalAction.legList]);
    }

  }, [modalAction, isTransportation]);

  const callbackClose = () => {
    setSuggestionList(null);
    setModalAction(null);
  };

  const getTrainLineList = (result: HttpsCallableResultType) => {
    if (!result.data.result)
      return null;
    const array = result.data.result.map((item: { name: string }) => ({ ...item, label: item.name }));
    // ensure result labels are unique, otherwise React complains of duplicate keys
    const unique: { label: string }[] = [];
    for (const item of array) {
      if (unique.find((item2) => item2.label === item.label))
        continue;
      unique.push(item);
    }
    return unique;
  };

  const getStationList = (result: HttpsCallableResultType) => {
    if (!result.data.Result)
      return null;
    const array = result.data.Result.map((item: any) => ({ ...item, Hiragana: katakanaToHiragana(item.Yomi), label: item.Suggest }));
    // ensure result labels are unique, otherwise React complains of duplicate keys
    const unique: { label: string }[] = [];
    for (const item of array) {
      if (unique.find((item2) => item2.label === item.label))
        continue;
      unique.push(item);
    }
    return unique;
  };

  const callbackOnChangeToGetSuggestions = (
    value: string,
    row: TransportationLegType,
    fieldname: SuggestableFieldType,
    suggestionFunction: HttpsCallableFunction,
    resultFunction: HttpsCallableResultHandler
  ) => {
    console.log('text value', value);
    if (!value) {
      setSuggestionList(null);
      setSuggestionField(null);
      return;
    }
    setSuggestionList('loading');
    suggestionFunction({ text: value })
      .then((result) => {
        console.log('api result', result);
        const list = resultFunction(result);
        if (!list) {
          setSuggestionList('no suggestions');
          return;
        }
        setSuggestionList(list);
        setSuggestionField({ rowid: row.id, fieldname });
      });
  };


  let modalBody = null;
  if (modalAction && legList) {
    if (isTransportation) {

      modalBody = <ModalTripInputTransportationTable
        legList={legList as TransportationLegType[]}
        setLegList={setLegList}
        isReadOnly={isReadOnly}
        editedCell={editedCell}
        setEditedCell={setEditedCell}
        railSearch={railSearch}
        getTrainLineList={getTrainLineList}
        stationSuggestion={stationSuggestion}
        getStationList={getStationList}
        suggestionList={suggestionList}
        clearSuggestionList={() => setSuggestionList(null)}
        suggestionField={suggestionField}
        callbackOnChangeToGetSuggestions={callbackOnChangeToGetSuggestions}
        isCommute={isCommute}
        userDetails={userDetails}
        attachmentDeletionMode={attachmentDeletionMode}
        setAttachmentDeletionMode={setAttachmentDeletionMode}
        modalAction={modalAction}
      />;

    } else {

      modalBody = <ModalTripInputNonTransportationTable
        legList={legList as NonTransportationLegType[]}
        setLegList={setLegList}
        isReadOnly={isReadOnly}
        editedCell={editedCell}
        setEditedCell={setEditedCell}
        userDetails={userDetails}
        attachmentDeletionMode={attachmentDeletionMode}
        setAttachmentDeletionMode={setAttachmentDeletionMode}
        modalAction={modalAction}
      />;

    }
  }

  return (

    <Modal show={!!modalAction} onHide={callbackClose} keyboard={false}
      //size='lg'
      dialogClassName='modal-90w'
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <div>
          {modalBody}
        </div>

      </Modal.Body>
      <Modal.Footer>
        <ButtonTW variant='blue' textSize='md' type='submit' onClick={(e) => {
          e.preventDefault();
          setSuggestionList(null);
          let legListToSave: EitherLegType[] | null = legList;
          // special case: just 1 empty row
          let oneEmptyRow = false;
          if (legListToSave.length === 1) {
            if (isTransportation) {
              const row = legListToSave[0] as TransportationLegType;
              if (row.modeOfTransportation === null
                && row.trainLine === ''
                && row.stationFrom === ''
                && row.stationTo === ''
                && row.legCost === null
                && row.memorandum === ''
              ) {
                oneEmptyRow = true;
              }
            } else {
              const row = legListToSave[0] as NonTransportationLegType;
              if (row.name === ''
                && row.cost === null
                && row.receipts.length === 0
                && row.memorandum === ''
              ) {
                oneEmptyRow = true;
              }
            }
          }

          if (oneEmptyRow) {
            legListToSave = null;

          } else {

            //validate input
            const errors: string[] = [];
            legListToSave.forEach((row0, index) => {
              if (isTransportation) {
                const row = row0 as TransportationLegType;
                if (!row.modeOfTransportation)
                  errors.push(`- Row ${1 + index}: Missing mode of transportation`);
                if (!row.trainLine && (row.modeOfTransportation === 'Train' || row.modeOfTransportation === 'Bus'))
                  errors.push(`- Row ${1 + index}: Missing train/bus line`);
                if (!row.stationFrom)
                  errors.push(`- Row ${1 + index}: Missing 'From' station`);
                if (!row.stationTo)
                  errors.push(`- Row ${1 + index}: Missing 'To' station`);
                if (!row.legCost)
                  errors.push(`- Row ${1 + index}: Missing cost`);
              } else {
                const row = row0 as NonTransportationLegType;
                if (!row.name)
                  errors.push(`- Row ${1 + index}: Missing name`);
                if (!row.cost)
                  errors.push(`- Row ${1 + index}: Missing cost`);
              }
            });
            if (errors.length) {
              alert(`Invalid data:\n\n${errors.join('\n')}`);
              return;
            }
          }
          console.log('saving', modalAction!.category, modalAction!.sIsoDate, legListToSave);

          modalAction!.saveLegList(legListToSave);

          callbackClose();
        }}>
          OK
        </ButtonTW>
        <ButtonTW variant='bsDarkGray' textSize='md' onClick={callbackClose}>
          Cancel
        </ButtonTW>
      </Modal.Footer>
    </Modal>



  );
}
