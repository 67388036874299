import { QuerySnapshot, collection, doc, getDoc, onSnapshot, query, where } from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { ButtonTW } from 'src/components/Buttons/ButtonTW';
import { RequestCodeLinkToAggregator } from 'src/components/ContextMenus/RequestCodeLinkToAggregator';
import { RequestCodeSelector } from 'src/components/FormControls/RequestCodeSelector';
import { ModalPopup } from 'src/components/Modal/ModalPopup';
import { getLoadingSpinnerOrNull } from 'src/components/Spinner/util_getLoadingSpinnerOrNull';
import { useAutosaveDocumentInList } from 'src/hooks/autosave/util_autosave';
import { useAppContext } from 'src/hooks/useAppContext';
import { QuotegridType } from 'src/types/types_quotegrid';
import { TourRequestType } from 'src/types/types_tourrequest';
import { dateisoFormatJpShort } from 'src/util/dateformattools';
import { dateFormatUserFriendly } from 'src/util/datelayouttools';
import { convertQuotegridSheetDates, convertTourRequestDates } from 'src/util/util_firestoredates';
import { createNewQuotegrid } from './createNewQuotegrid';
import { addMetadataModifiedQuotegrids } from './QuotegridCrud/util_db_quotegrids';
import './quotegridlist.css';


export function QuotegridList() {

  const { db, setDbError, userDetails } = useAppContext();

  const userSimple = useMemo(() => {
    return {
      uid: userDetails.id,
      email: userDetails.email,
      name: userDetails.displayNameEn,
    };
  }, [userDetails]);

  const [shownPopup, setShownPopup] = useState<string | null>(null);

  // below state is for creating new quotegrid
  const [showModal, setShowModal] = useState(false);
  const [tripCode, setTripCode] = useState<string>('');
  const [paxName, setPaxName] = useState<string>('');
  const [tourRequest, setTourRequest] = useState<TourRequestType | null>();
  const [quotegridSheetName, setQuotegridSheetName] = useState<string>();
  // ------------------------------

  const [quotegrids, setQuotegrids] = useState<QuotegridType[]>();
  useEffect(() => {

    const processSnapshot = function (snapshot: QuerySnapshot) {
      const quotegrids: QuotegridType[] = [];
      console.log(`Retrieved ${snapshot.docs.length} quotegrids from firestore`);
      for (const doc of snapshot.docs) {
        const quotegrid = { ...doc.data(), id: doc.id } as QuotegridType;
        convertQuotegridSheetDates(quotegrid);
        quotegrids.push(quotegrid);
      }
      setQuotegrids(quotegrids);
    };

    const q = query(
      collection(db, 'quotationsheets'),
      where('_isDeleted', '==', false),
    );
    const unsubscribe = onSnapshot(q, processSnapshot, (err) => setDbError('Getting quotegrid list', err));

    return unsubscribe;
  }, [db, setDbError]);


  const setTourRequestId = (tourrequestId: string) => {
    getDoc(doc(db, 'tourrequests', tourrequestId))
      .then((docu) => {
        const tourrequestFirestore = { ...docu.data(), id: docu.id } as TourRequestType;
        convertTourRequestDates(tourrequestFirestore);
        setTourRequest(tourrequestFirestore);
        console.log('retrieved request from firestore', tourrequestFirestore);
      });
  };


  const autosaveNewStep = useAutosaveDocumentInList('quotationsheets', addMetadataModifiedQuotegrids);

  /**** ALL HOOKS ABOVE ****/


  const loadingSpinner = getLoadingSpinnerOrNull([
    ['quotegrid sheets', quotegrids],
  ]);
  if (!quotegrids)
    return loadingSpinner;


  return (
    <div className='container page-quotegridlist'>
      <Helmet><title>Quotegrid Sheet List</title></Helmet>

      <h2 className='my-4'>List of quotegrid sheets</h2>

      <table className='table'>
        <thead>
          <tr>
            <th></th>
            <th>Request code</th>
            <th>Pax name</th>
            <th>Sheet name</th>
            <th>Tour dates</th>
            <th>Num of pax</th>
            <th>Created</th>
            <th>Modified</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {quotegrids.map((quotegrid) => {
            return (
              <tr key={quotegrid.id}>
                <td>
                  <ButtonTW variant='blue_outline' to={`edit/${quotegrid.id}`}>Edit</ButtonTW></td>
                <td>
                  <RequestCodeLinkToAggregator
                    requestCode={quotegrid.requestInfo.requestCode}
                    linkId={quotegrid.id}
                    shownPopup={shownPopup}
                    setShownPopup={setShownPopup}
                  />
                </td>
                <td>{quotegrid.requestInfo.travellerName}</td>
                <td>{quotegrid.quotegridSheetName}</td>
                <td>
                  {dateisoFormatJpShort(quotegrid.requestInfo.dateisoTourStart)} -
                  {dateisoFormatJpShort(quotegrid.requestInfo.dateisoTourEnd)}
                </td>
                <td>{quotegrid.requestInfo.numOfPax}</td>
                <td>
                  {dateFormatUserFriendly(quotegrid.metadataCreated.dateCreated)}
                  {quotegrid.metadataCreated.userCreated.name}
                </td>
                <td>
                  {dateFormatUserFriendly(quotegrid.metadataModified.dateModified)}
                  {quotegrid.metadataModified.userModified.name}
                </td>
                <td>
                  <ButtonTW variant='blue_outline' onClick={(e) => {
                    if (!window.confirm('Are you sure you want to delete this quotegrid sheet?'))
                      return;

                    const updateObj: Partial<QuotegridType> = {
                      _isDeleted: true,
                    };

                    autosaveNewStep('DELETE', quotegrid, updateObj, 'UNDOWALL')
                      .then(() => console.log('Deleted quotegrid sheet'));
                  }}>Delete</ButtonTW>
                </td>
              </tr>
            );
          }


          )}
        </tbody>
      </table>

      <div>
        <ButtonTW variant='blue' textSize='md' onClick={(e) => {
          // reset popup fields
          setTripCode('');
          setPaxName('');
          setTourRequest(null);
          setQuotegridSheetName('Main');

          setShowModal(true);
        }}>Create new quotegrid sheet</ButtonTW>
      </div>


      <ModalPopup
        title={'Create new quotegrid sheet'}
        okLabel='Create'
        show={showModal}
        className='modal-superlarge'
        callbackClose={() => setShowModal(false)}
        onSubmit={(e, onSuccess) => {
          e.preventDefault();

          if (!tripCode || !tourRequest) {
            window.alert('Select request code');
            return;
          }

          if (!quotegridSheetName) {
            window.alert('Enter sheet name');
            return;
          }

          console.log('tourrequest', tourRequest);

          createNewQuotegrid(db, userDetails, tourRequest, tourRequest.id, quotegridSheetName)
            .then((result) => {
              if (result === 'success')
                setShowModal(false);
              else
                window.alert(`Error creating quotegrid sheet: ${result}`);
            });

        }}
        body={

          <div>
            <div className='mb-2'>

            </div>
            <div className=''>
              <RequestCodeSelector
                tripCode={tripCode}
                paxName={paxName}
                setRequestData={({ requestCode, paxName, tourrequestId }) => {
                  setTripCode(requestCode);
                  setPaxName(paxName);
                  if (tourrequestId)
                    setTourRequestId(tourrequestId);
                }}
              />
            </div>

            {/* <div>Pax name</div>
            <div>
              <Form.Control type='text' value={paxName} disabled={true} />
            </div> */}

            <div>Quotegrid sheet name</div>
            <div>
              <Form.Control type='text' value={quotegridSheetName} onChange={(e) => setQuotegridSheetName(e.target.value)} />
            </div>
          </div>

        } />

    </div>
  );
}
