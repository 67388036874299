import { addDoc, collection, doc, DocumentSnapshot, onSnapshot } from 'firebase/firestore';
import { FormEvent, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { ButtonTW } from 'src/components/Buttons/ButtonTW';
import { FileDownloadUploadButtons } from 'src/components/FileDownloadUpload/FileDownloadUploadButtons';
import { RequestCodeSelector } from 'src/components/FormControls/RequestCodeSelector';
import { getLoadingSpinnerOrNull } from 'src/components/Spinner/util_getLoadingSpinnerOrNull';
import { InfoToolTip } from 'src/components/ToolTip/InfoToolTip';
import { useUndoRedo } from 'src/hooks/autosave/useUndoRedo';
import { autosaveDocument } from 'src/hooks/autosave/util_autosave';
import { useAppContext } from 'src/hooks/useAppContext';
import { InvoiceType, InvoiceUnsavedType } from 'src/types/types_invoices';
import { PayeeType } from 'src/types/types_payee';
import { UserSimpleUidType } from 'src/types/types_user';
import { iso_from_local0, local0_from_iso } from 'src/util/datetools';
import { userrole_canAddInvoice } from 'src/util/user_roles';
import { verifyNotDeleted } from 'src/util/util_db_misc';
import { convertInvoiceDates, serverTimestampAsDate } from 'src/util/util_firestoredates';
import { formatNum } from 'src/util/util_formatnum';
import { log_db_write } from 'src/util/util_log';
import { TopWhiteBarEditControls } from '../../hooks/autosave/TopWhiteBarEditControls';
import { DateInput } from '../ExpenseSheet/DateInput';
import { getShortDisplayNameEn, getShortDisplayNameJa } from '../Payees/util_payees';
import { EditableFieldAddInvoice } from './EditableFieldAddInvoice';
import { PayeeTypeahead } from './PayeeTypeahead';
import './addinvoice.css';
import { getBlankInvoice, getUpdateObjAfterInvoiceFileUpload, getUpdateObjToDeleteFile } from './util_invoices';


type InvoiceUpdateType = Omit<InvoiceType,
  'id'
  | '_isDeleted'
  | 'paymentDateiso'
  | 'paymentSourceAccount'
  | 'paymentUserEmail'
  | 'paymentUserUid'
  | 'paymentSetDatetime'
  | 'status'
  | 'statusPaidOrScheduled'
  | 'dateCreated'
  | 'uid'
  | 'userEmail'
  | 'userCreatedName'
>;


type WithholdingTaxBufferType = {
  amountBeforeConsumptionTax: number | null;
  amountConsumptionTax: number | null;
  amountAfterConsumptionTax: number | null;
  amountWithholdingTax: number | null;
};

interface AddInvoiceProps {
  setRecentlyInsertedId: (id: string) => void;
  payeeList: PayeeType[] | undefined;
  action: 'add' | 'edit';
}

export function AddInvoice({
  setRecentlyInsertedId,
  payeeList,
  action,
}: AddInvoiceProps) {

  const { db, setDbError, storage, userDetails, _lang } = useAppContext();

  if (!userrole_canAddInvoice(userDetails.roles)) {
    setDbError('Unauthorized to add/edit invoices');
    throw new Error('Unauthorized to add/edit invoices');
  }

  const { invoiceId } = useParams();

  const navigate = useNavigate();

  const [ccyList, setCcyList] = useState(['JPY', 'USD', 'EUR', 'GBP', 'AUD',
    //'NZD', 'CAD', 'CHF', 'SEK', 'NOK', 'DKK', 'HKD', 'SGD', 'KRW', 'CNY', 'TWD', 'THB', 'PHP', 'MYR', 'IDR', 'INR', 'VND'
  ]);
  const [formStatus, setFormStatus] = useState({ className: '', msg: '' });


  // temporary buffer for typeahead control, can be null
  const [payeeId, setPayeeId] = useState<string | null>(null);


  //sanity check
  if (!((action === 'add' && !invoiceId) || (action === 'edit' && invoiceId)))
    throw new Error('Invalid action');


  // autosave and undo/redo state
  const { addToCache, getUndoRedoHistoryChanges } = useUndoRedo<InvoiceType>('invoiceshistory');
  const [enableEditing, setEnableEditing] = useState(action === 'add');
  const [saveStatus, setSaveStatus] = useState<string>();
  const [editedCell, setEditedCell] = useState<string | null>(null);


  // load existing invoice if action==edit
  const [invoice, setInvoice] = useState<InvoiceUnsavedType>();
  useEffect(() => {

    if (!invoiceId) {
      const userSimple: UserSimpleUidType = {
        uid: userDetails.id,
        email: userDetails.email,
        name: userDetails.displayNameEn,
      };
      const blankInvoice = getBlankInvoice(userSimple);
      setInvoice(blankInvoice);
      return;
    }

    const processSnapshot = function (docu: DocumentSnapshot) {
      const invoice = { ...docu.data(), id: docu.id } as InvoiceType;
      verifyNotDeleted(docu.exists(), invoice, invoiceId, setDbError, 'invoice');
      convertInvoiceDates(invoice);
      console.log('invoice', invoice);
      setInvoice(invoice);
      setPayeeId(invoice.payeeId);

      if (invoice.history?.currentStepId) {
        addToCache(invoice.history.currentStepId, invoice);
      }
    };

    const q = doc(db, 'invoices', invoiceId);
    const unsubscribe = onSnapshot(q, processSnapshot, (err) => setDbError(`Getting invoice ${invoiceId}`, err));
    return unsubscribe;
  }, [db, setDbError, userDetails, invoiceId, addToCache]);



  // *** all hooks above this line

  const loadingSpinner = getLoadingSpinnerOrNull([
    ['supplier list', payeeList],
    ['invoice', invoice],
  ]);
  if (!payeeList || !invoice)
    return loadingSpinner;



  const addMetadataModifiedInvoice = (updateObj: Partial<InvoiceType>, userSimple: UserSimpleUidType) => {
    updateObj.dateModified = serverTimestampAsDate();
    updateObj.userModifiedUid = userSimple.uid;
    updateObj.userModifiedEmail = userSimple.email;
    updateObj.userModifiedName = userSimple.name;
  };

  const autosaveNewStep = async (
    userAction: string,
    updateObj: Partial<InvoiceType>,
    sUndoWall: 'u' | 'UNDOWALL', // u = undoable
  ) => {
    return autosaveAnyStep(userAction, updateObj, false, undefined, sUndoWall);
  };

  const autosaveAnyStep = async (
    userAction: string,
    updateObj: any,
    isUndoRedo: boolean,
    undoRedoTargetStep: number | undefined,
    sUndoWall: 'u' | 'UNDOWALL', // u = undoable
  ) => {

    if (!enableEditing) {
      setDbError('Field change despite editing being disabled');
      throw new Error('Field change despite editing being disabled');
    }

    const userSimple = {
      uid: userDetails.id,
      email: userDetails.email,
      name: userDetails.displayNameEn,
    };

    autosaveDocument(
      updateObj,
      userAction,
      isUndoRedo,
      undoRedoTargetStep,
      sUndoWall,
      invoiceId!, // autosaveAnyStep only called when action==='add', in which case invoiceId is always defined
      invoice.history,
      userSimple,
      db,
      'invoices',
      (updateObj) => addMetadataModifiedInvoice(updateObj, userSimple),
      setSaveStatus,
    )
      .catch((err) => setDbError(`Autosave [autosaveDocument] invoice id=${invoiceId} action=[${userAction}]`, err));
  };

  const autosaveUndoRedoStep = async (action: 'Undo' | 'Redo', targetStep: number) => {

    const undoRedoData = await getUndoRedoHistoryChanges(action, targetStep, invoice.history);
    if (!undoRedoData)
      // failed to retrieve history step from db
      return;

    const { updateObjHistory, targetStepObj } = undoRedoData;

    // fields that we remove in general:
    //   - id: we never save id as a field
    //   - _isDeleted: should always be false
    //   - history: specifically tweaked in a precise way by updateObjHistory
    //   - dateCreated, userCreated: immutable metadata
    //   - dateModified, userModified: metadata set upon save
    // invoice fields that we remove:
    //   - tbd

    // delete the field we specifically don't want:
    delete targetStepObj.id;
    delete targetStepObj._isDeleted;
    delete targetStepObj.history;
    // @ts-expect-error field parentDocumentId doesn't exist on type InvoiceType
    delete targetStepObj.parentDocumentId;

    delete targetStepObj.dateCreated;
    delete targetStepObj.userEmail;
    delete targetStepObj.userCreatedName;
    delete targetStepObj.uid;

    delete targetStepObj.dateModified;
    delete targetStepObj.userModifiedEmail;
    delete targetStepObj.userModifiedName;
    delete targetStepObj.userModifiedUid;

    // add more immutable fields as needed:
    // here we should basically add all fields that are not editable through the CRUD UI
    // delete targetStepObj.status
    // for now we keep status. TODO: split out statusPayment(null|PAID|SCHEDULED) from other statuses. RECEIVED/EXPECTED isn't needed as can just check files.length.

    delete targetStepObj.filesAll; // this should be maintained as a permanent record of all files ever uploaded on this invoice

    const updateObj: Partial<InvoiceType> = {
      ...targetStepObj,
      ...updateObjHistory,
    };

    autosaveAnyStep(
      action, // this isn't actually used
      updateObj,
      true,
      targetStep,
      'u', // undo/redo step is always undoable
    );
  };


  const setFormError = (msg: string) => setFormStatus({ className: 'alert-warning', msg: msg });
  const setFormOk = (msg: string) => setFormStatus({ className: 'alert-primary', msg: msg });


  const submitHandler = async function (e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (action !== 'add') {
      // when editing, we autosave, so no form submission
      return;
    }

    try {
      if (!invoice.payeeId) {
        setFormError(_lang('Supplier missing', '支払先が必要'));
        return;
      }

      if (typeof invoice.amount !== 'number') {
        setFormError(_lang('amount missing or invalid', '有効な金額が必要'));
        return;
      }

      if (invoice.hasWithholdingTax) {
        if (typeof invoice.amountBeforeConsumptionTax !== 'number') {
          setFormError('Amount before tax is invalid');
          return;
        }
        if (typeof invoice.amountConsumptionTax !== 'number') {
          setFormError('Consumption tax is invalid');
          return;
        }
        if (typeof invoice.amountAfterConsumptionTax !== 'number') {
          setFormError('Amount after tax is invalid');
          return;
        }
        if (typeof invoice.amountWithholdingTax !== 'number') {
          setFormError('Withholding tax is invalid');
          return;
        }
      }

      if (!invoice.currency) {
        setFormError(_lang('currency missing', '通過が必要'));
        return;
      }

      if (!invoice.dateisoInvoiceReceived) {
        setFormError(_lang('received date missing or invalid', '有効な受取日が必要'));
        return;
      }

      if (!invoice.dateisoDeadline) {
        setFormError(_lang('pay by date missing or invalid', '有効な支払期限'));
        return;
      }

      setFormOk(_lang('Saving invoice', '請求書情報を保存中'));

      const { id: _, ...addObj } = invoice;

      const result = await addDoc(collection(db, 'invoices'), addObj);
      console.log('added invoice result', result, 'id=', result.id);
      const addedInvoiceId = result.id;

      log_db_write({ db, userDetails, logkey: 'db_write.invoice.add', desc: `Added new invoice [${invoice.payeeNameJa}] [${invoice.payeeNameEn}] [${formatNum(invoice.amount)}] [${invoiceId ?? addedInvoiceId}]` });

      navigate('/invoices/');
    } catch (err) {
      console.error(err);
      const msg = `${_lang('Error saving invoice db entry', 'データベースに請求書を保存できませんでした')}: ${err}`;
      setFormError(msg);
    }

  };


  const calcPaymentAmountOnWithholdingAmountChange = (withholdingTaxAmount: number, updateObj: Partial<InvoiceType>) => {
    if (invoice.amountAfterConsumptionTax !== undefined && invoice.amountAfterConsumptionTax !== null) {
      updateObj.amount = invoice.amountAfterConsumptionTax - withholdingTaxAmount;
    }
  };

  const calcPaymentAmountOnAfterTaxChange = (amountAfterTax: number, updateObj: Partial<InvoiceType>) => {
    if (invoice.amountWithholdingTax !== undefined && invoice.amountWithholdingTax !== null) {
      updateObj.amount = amountAfterTax - invoice.amountWithholdingTax;
    }
  };

  let pageTitle = '';
  if (invoiceId) {
    pageTitle = 'Edit invoice';
    if (invoice.payeeNameEn) {
      pageTitle += ` [${invoice.payeeNameEn}]`;
    }
    if (invoice.tripcode) {
      pageTitle += ` [${invoice.tripcode}]`;
    }
    if (invoice.paxname) {
      pageTitle += ` [${invoice.paxname}]`;
    }
  } else {
    pageTitle = 'Add invoice';
  }

  return (
    <div>
      <Helmet><title>{pageTitle}</title></Helmet>

      <TopWhiteBarEditControls
        whiteBarActive={action === 'edit'}
        enableEditing={enableEditing}
        setEnableEditing={setEnableEditing}
        saveStatus={saveStatus}
        setSaveStatus={setSaveStatus}
        autosaveUndoRedoStep={autosaveUndoRedoStep}
        history={invoice.history}
        divFloatingTotals={null}
        userIsAllowedToEdit={true}
      />


      <form id='formAddInvoice' onSubmit={submitHandler}>
        <div className='gridAddInvoice tw-pt-4'>

          <h3 className='col1'>{invoiceId ? 'Edit invoice' : _lang('Add invoice', '請求書を追加する')}</h3>



          {/* FIELD: SUPPLIER */}

          <div className='col1'>
            <label>{_lang('Supplier', '支払先')}</label>
          </div>
          <div className='invoice-col2'>
            <PayeeTypeahead id='payee' payeeList={payeeList} payeeId={payeeId} disabled={!enableEditing}
              setPayeeObj={(payeeObj) => {
                if (!payeeObj) {
                  // don't save to db
                  setPayeeId(null);
                  return;
                }
                const updateObj: Partial<InvoiceType> = {
                  payeeId: payeeObj.id,
                  payeeNameJa: getShortDisplayNameJa(payeeObj),
                  payeeNameEn: getShortDisplayNameEn(payeeObj),
                  payeeCategories: payeeObj.categories,
                };

                if (action === 'add') {
                  setInvoice((invoice) => { return { ...invoice!, ...updateObj }; });
                  setPayeeId(payeeObj.id);
                } else {
                  autosaveNewStep(`Change supplier to ‘${getShortDisplayNameJa(payeeObj)}’`, updateObj, 'u');
                }
              }}
              callbackNewPayee={(payeeName) => {
                const params: Record<string, string> = {};
                params.name = payeeName;
                if (invoiceId)
                  params.invoiceIds = invoiceId;
                params.returnTo = 'addinvoice';
                const queryString = new URLSearchParams(params).toString();
                const url = `/suppliers/add?${queryString}`;
                navigate(url);
              }}
              onBlur={() => setPayeeId(invoice.payeeId)}
            />
          </div>



          {/* FIELD: FILE UPLOAD */}

          <div className='col1'>
            <label>{_lang('File', '添付ファイル')}</label>
          </div>
          <div className='invoice-col2'>
            <FileDownloadUploadButtons
              isReadOnly={!enableEditing}
              isExpandable={true}
              listFiles={invoice.files}
              uploadButtonId='uploadButton'
              storageBaseFolder='invoices'
              callbackUploadSuccess={(uploadedFilePathsAndURLs) => {
                const { updateObj, userAction } = getUpdateObjAfterInvoiceFileUpload(invoice, uploadedFilePathsAndURLs);

                if (action === 'add') {
                  setInvoice((invoice) => { return { ...invoice!, ...updateObj }; });
                } else {
                  autosaveNewStep(userAction, updateObj, 'u');
                }
              }}
              handleDeleteFile={(file) => {
                const { updateObj, userAction } = getUpdateObjToDeleteFile(invoice, file);

                if (action === 'add') {
                  setInvoice((invoice) => { return { ...invoice!, ...updateObj }; });
                } else {
                  autosaveNewStep(userAction, updateObj, 'u');
                }
              }}
              loginfo={`invoice ${invoiceId ?? 'new'}`}
            />

          </div>



          {/* FIELD: REQUEST CODE */}

          <RequestCodeSelector
            tripCode={invoice.tripcode}
            paxName={invoice.paxname}
            disabled={!enableEditing}
            setRequestData={({ requestCode, paxName, tourrequestId }) => {
              const updateObj: Partial<InvoiceType> = {
                tripcode: requestCode,
                paxname: paxName,
              };
              if (action === 'add') {
                setInvoice((invoice) => { return { ...invoice!, ...updateObj }; });
              } else {
                autosaveNewStep(`Set request code/pax name to ‘${requestCode}’/‘${paxName}’`, updateObj, 'u');
              }
            }}
          />

          {/* <div className='col1'>
            <label htmlFor="paxName">{_lang('Pax name', '旅行者名')}</label>
          </div>
          <div className='invoice-col2'>
            <input id="paxName" className='form-control' value={invoice.paxname} onChange={(e) => setInvoice((invoice) => ({ ...invoice, paxname: e.target.value }))} />
          </div> */}



          {/* FIELD: SERVICE PURCHASED */}

          <div className='col1'>
            <label htmlFor='servicePurchased'>{_lang('Service purchased', 'サービスの内容 (英語)')}</label>
          </div>
          <div className='invoice-col2'>
            <EditableFieldAddInvoice
              tableid='invoice'
              rowid='main'
              fieldname='servicePurchased'
              validationType=''
              currentValue={invoice.servicePurchased}
              isClickableToEdit={enableEditing}
              editedCell={editedCell}
              setEditedCell={setEditedCell}
              callbackCommitChange={(dbvalue: any) => {
                const updateObj: Partial<InvoiceType> = {
                  servicePurchased: dbvalue,
                };
                if (action === 'add') {
                  setInvoice((invoice) => { return { ...invoice!, ...updateObj }; });
                } else {
                  autosaveNewStep(`Change service purchased to ‘${dbvalue}’`, updateObj, 'u');
                }
                setEditedCell(null);
              }}
            />
          </div>



          {/* FIELD: WITHHOLDING TAX CHECKBOX */}

          <div className='col1'>
          </div>
          <div className='invoice-col2 cellWithholdingTax'>
            <div>
              <Form.Check id='chkHasWitholdingTax' label='This invoice has withholding tax'
                checked={invoice.hasWithholdingTax || false} disabled={!enableEditing}
                onChange={(e) => {
                  const updateObj: Partial<InvoiceType> = {
                    hasWithholdingTax: e.target.checked,
                  };
                  if (action === 'add') {
                    setInvoice((invoice) => { return { ...invoice!, ...updateObj }; });
                  } else {
                    autosaveNewStep(`Toggle withholding tax ${e.target.checked ? 'on' : 'off'}`, updateObj, 'u');
                  }
                }} />
            </div>
            <div>
            </div>
          </div>



          {/* WITHHOLDING TAX BLOCK */}

          <div className='withholdingBlock' style={invoice.hasWithholdingTax ? undefined : { display: 'none' }}>
            <div className='col1'>
              Cost before deducting withholding tax:
            </div>
            <div className='invoice-col2 three-col-grid'>
              <div>Excl. consumption tax</div>
              <div>Consumption tax</div>
              <div>Incl. Consumption tax</div>

              {/* FIELD: EXCL CONSUMPTION TAX */}

              <EditableFieldAddInvoice
                tableid='invoice'
                rowid='main'
                fieldname='amountBeforeConsumptionTax'
                validationType='number'
                currentValue={invoice.amountBeforeConsumptionTax}
                isClickableToEdit={enableEditing}
                editedCell={editedCell}
                setEditedCell={setEditedCell}
                callbackCommitChange={(dbvalue: any) => {
                  if (typeof dbvalue === 'number') {
                    const consumptionTax = Math.floor(dbvalue * 0.1);
                    const amountAfterTax = dbvalue + consumptionTax;
                    const updateObj: Partial<InvoiceType> = {
                      amountBeforeConsumptionTax: dbvalue,
                      amountConsumptionTax: consumptionTax,
                      amountAfterConsumptionTax: amountAfterTax,
                    };
                    calcPaymentAmountOnAfterTaxChange(amountAfterTax, updateObj);
                    if (action === 'add') {
                      setInvoice((invoice) => { return { ...invoice!, ...updateObj }; });
                    } else {
                      autosaveNewStep(`Change amount before tax to ‘${formatNum(dbvalue)}’`, updateObj, 'u');
                    }
                    setEditedCell(null);
                  } else {
                    throw new Error(`not a number [${dbvalue}]`);
                  }
                }}
              />
              {/* <input id='amount-before-tax' className='form-control text-end invoice-number' value={amountBeforeTaxStr} onChange={(e) => {
              <input id='amount-before-tax' className='form-control text-end invoice-number' value={amountBeforeTaxStr} onChange={(e) => {
                const [isValid, dbvalue, formattedValue] = validateInput('number', e.target.value)
                setAmountBeforeTaxStr(formattedValue)
                e.target.style.color = isValid ? '' : 'red'
                if (isValid) {
                  const consumptionTax = Math.floor(dbvalue * 0.1)
                  const amountAfterTax = dbvalue + consumptionTax
                  setAmountTaxStr(formatNum(consumptionTax))
                  setAmountAfterTaxStr(formatNum(amountAfterTax))
                  calcPaymentAmountOnAfterTaxChange(amountAfterTax)
                }
              }} /> */}

              {/* FIELD: CONSUMPTION TAX */}

              <EditableFieldAddInvoice
                tableid='invoice'
                rowid='main'
                fieldname='amountConsumptionTax'
                validationType='number'
                currentValue={invoice.amountConsumptionTax}
                isClickableToEdit={enableEditing}
                editedCell={editedCell}
                setEditedCell={setEditedCell}
                callbackCommitChange={(dbvalue: any) => {
                  if (typeof dbvalue === 'number') {
                    const updateObj: Partial<InvoiceType> = {
                      amountConsumptionTax: dbvalue,
                    };

                    if (invoice.amountBeforeConsumptionTax !== undefined && invoice.amountBeforeConsumptionTax !== null) {
                      const amountAfterTax = invoice.amountBeforeConsumptionTax + dbvalue;
                      updateObj.amountAfterConsumptionTax = amountAfterTax;
                      calcPaymentAmountOnAfterTaxChange(amountAfterTax, updateObj);
                    }

                    if (action === 'add') {
                      setInvoice((invoice) => { return { ...invoice!, ...updateObj }; });
                    } else {
                      autosaveNewStep(`Change amount consumption tax to ‘${formatNum(dbvalue)}’`, updateObj, 'u');
                    }
                    setEditedCell(null);
                  } else {
                    throw new Error(`not a number [${dbvalue}]`);
                  }
                }}
              />
              {/* <input id='amount-tax' className='form-control text-end invoice-number' value={amountTaxStr} onChange={(e) => {
              }} />
              <input id='amount-tax' className='form-control text-end invoice-number' value={amountTaxStr} onChange={(e) => {
                const [isValid, dbvalue, formattedValue] = validateInput('number', e.target.value)
                setAmountTaxStr(formattedValue)
                e.target.style.color = isValid ? '' : 'red'
                if (isValid) {
                  const [isValidBeforeTax, dbvalueBeforeTax] = validateInput('number', amountBeforeTaxStr)
                  if (isValidBeforeTax) {
                    const amountAfterTax = dbvalueBeforeTax + dbvalue
                    setAmountAfterTaxStr(formatNum(amountAfterTax))
                    calcPaymentAmountOnAfterTaxChange(amountAfterTax)
                  }
                }
              }} /> */}

              {/* FIELD: AFTER CONSUMPTION TAX */}

              <EditableFieldAddInvoice
                tableid='invoice'
                rowid='main'
                fieldname='amountAfterConsumptionTax'
                validationType='number'
                currentValue={invoice.amountAfterConsumptionTax}
                isClickableToEdit={enableEditing}
                editedCell={editedCell}
                setEditedCell={setEditedCell}
                callbackCommitChange={(dbvalue: any) => {
                  if (typeof dbvalue === 'number') {
                    const amountBeforeTax = Math.ceil(dbvalue / 1.1);
                    const consumptionTax = dbvalue - amountBeforeTax;

                    const updateObj: Partial<InvoiceType> = {
                      amountBeforeConsumptionTax: amountBeforeTax,
                      amountConsumptionTax: consumptionTax,
                      amountAfterConsumptionTax: dbvalue,
                    };

                    calcPaymentAmountOnAfterTaxChange(dbvalue, updateObj);

                    if (action === 'add') {
                      setInvoice((invoice) => { return { ...invoice!, ...updateObj }; });
                    } else {
                      autosaveNewStep(`Change amount consumption tax to ‘${formatNum(dbvalue)}’`, updateObj, 'u');
                    }
                    setEditedCell(null);
                  } else {
                    throw new Error(`not a number [${dbvalue}]`);
                  }
                }}
              />
              {/* <input id='amount-after-tax' className='form-control text-end invoice-number' value={amountAfterTaxStr} onChange={(e) => {
              }} />
              <input id='amount-after-tax' className='form-control text-end invoice-number' value={amountAfterTaxStr} onChange={(e) => {
                const [isValid, dbvalue, formattedValue] = validateInput('number', e.target.value)
                setAmountAfterTaxStr(formattedValue)
                e.target.style.color = isValid ? '' : 'red'
                if (isValid) {
                  const amountBeforeTax = Math.ceil(dbvalue / 1.1)
                  const consumptionTax = dbvalue - amountBeforeTax
                  setAmountBeforeTaxStr(formatNum(amountBeforeTax))
                  setAmountTaxStr(formatNum(consumptionTax))
                  calcPaymentAmountOnAfterTaxChange(dbvalue)
                }
              }} /> */}
            </div>

            <div className='col1'>
              Withholding tax amount:
            </div>
            <div className='invoice-col2 number-and-button'>

              {/* FIELD: WITHHOLDING TAX AMOUNT */}

              <div className='tw-w-[12em]'>
                <EditableFieldAddInvoice
                  tableid='invoice'
                  rowid='main'
                  fieldname='amountWithholdingTax'
                  validationType='number'
                  currentValue={invoice.amountWithholdingTax}
                  isClickableToEdit={enableEditing}
                  editedCell={editedCell}
                  setEditedCell={setEditedCell}
                  callbackCommitChange={(dbvalue: any) => {
                    if (typeof dbvalue === 'number') {
                      const updateObj: Partial<InvoiceType> = {
                        amountWithholdingTax: dbvalue,
                      };

                      calcPaymentAmountOnWithholdingAmountChange(dbvalue, updateObj);

                      if (action === 'add') {
                        setInvoice((invoice) => { return { ...invoice!, ...updateObj }; });
                      } else {
                        autosaveNewStep(`Change amount consumption tax to ‘${formatNum(dbvalue)}’`, updateObj, 'u');
                      }
                      setEditedCell(null);
                    } else {
                      throw new Error(`not a number [${dbvalue}]`);
                    }
                  }}
                />
              </div>
              {/* <input id='amount-withholding-tax' className='form-control text-end invoice-number' value={amountWithholdingTaxStr} onChange={(e) => {
              <input id='amount-withholding-tax' className='form-control text-end invoice-number' value={amountWithholdingTaxStr} onChange={(e) => {
                const [isValid, dbvalue, formattedValue] = validateInput('number', e.target.value)
                setAmountWithholdingTaxStr(formattedValue)
                if (isValid) {
                  calcPaymentAmountOnWithholdingAmountChange(dbvalue)
                }
                e.target.style.color = isValid ? '' : 'red'
              }} /> */}

              {/* BUTTON: FROM ZEI NUKI */}

              <ButtonTW variant='blue_outline' onClick={() => {
                if (invoice.amountBeforeConsumptionTax !== undefined && invoice.amountBeforeConsumptionTax !== null) {
                  const withholdingTaxAmount = Math.floor(invoice.amountBeforeConsumptionTax * 0.1021);
                  const updateObj: Partial<InvoiceType> = {
                    amountWithholdingTax: withholdingTaxAmount,
                  };
                  calcPaymentAmountOnWithholdingAmountChange(withholdingTaxAmount, updateObj);

                  if (action === 'add') {
                    setInvoice((invoice) => { return { ...invoice!, ...updateObj }; });
                  } else {
                    autosaveNewStep('Calc withholding tax from zeinuki', updateObj, 'u');
                  }
                  setEditedCell(null);
                }
              }}><i className='bi bi-chevron-double-left'></i> 税抜 × 10.21%</ButtonTW>

              {/* BUTTON: FROM ZEI KOMI */}

              <ButtonTW variant='blue_outline' onClick={() => {
                if (invoice.amountAfterConsumptionTax !== undefined && invoice.amountAfterConsumptionTax !== null) {
                  const withholdingTaxAmount = Math.floor(invoice.amountAfterConsumptionTax * 0.1021);
                  const updateObj: Partial<InvoiceType> = {
                    amountWithholdingTax: withholdingTaxAmount,
                  };
                  calcPaymentAmountOnWithholdingAmountChange(withholdingTaxAmount, updateObj);

                  if (action === 'add') {
                    setInvoice((invoice) => { return { ...invoice!, ...updateObj }; });
                  } else {
                    autosaveNewStep('Calc withholding tax from zeikomi', updateObj, 'u');
                  }
                  setEditedCell(null);
                }
              }}><i className='bi bi-chevron-double-left'></i> 税込 × 10.21%</ButtonTW>
            </div>
          </div>



          {/* ROW: PAYMENT AMOUNT */}

          <div className='col1'>
            <label htmlFor='amount'>{_lang('Payment amount', '支払金額')}</label>
          </div>
          <div className='invoice-col2 cellAmount'>

            {/* FIELD: PAYMENT AMOUNT */}

            <EditableFieldAddInvoice
              tableid='invoice'
              rowid='main'
              fieldname='amount'
              validationType='number'
              currentValue={invoice.amount}
              isClickableToEdit={enableEditing}
              editedCell={editedCell}
              setEditedCell={setEditedCell}
              callbackCommitChange={(dbvalue: any) => {
                if (typeof dbvalue === 'number') {
                  const updateObj: Partial<InvoiceType> = {
                    amount: dbvalue,
                  };
                  if (action === 'add') {
                    setInvoice((invoice) => { return { ...invoice!, ...updateObj }; });
                  } else {
                    autosaveNewStep(`Change payment amount to ‘${formatNum(dbvalue)}’`, updateObj, 'u');
                  }
                  setEditedCell(null);
                } else {
                  throw new Error(`not a number [${dbvalue}]`);
                }
              }}
            />
            {/* <input id='amount' className='form-control text-end invoice-number' value={amountStr} onChange={(e) => {
            <input id='amount' className='form-control text-end invoice-number' value={amountStr} onChange={(e) => {
              const [isValid, dbvalue, formattedValue] = validateInput('number', e.target.value)
              setAmountStr(formattedValue)
              e.target.style.color = isValid ? '' : 'red'
            }} /> */}

            {/* FIELD: CURRENCY */}

            <select id='currency' className='form-select' value={invoice.currency} disabled={!enableEditing} onChange={(e) => {
              const updateObj: Partial<InvoiceType> = {
                currency: e.target.value,
              };

              if (action === 'add') {
                setInvoice((invoice) => { return { ...invoice!, ...updateObj }; });
              } else {
                autosaveNewStep(`Change currency to ‘${e.target.value}’`, updateObj, 'u');
              }
            }}>
              {ccyList.map((c) => (
                <option key={c}>{c}</option>
              ))}
            </select>
          </div>



          {/* FIELD: RECEIVED ON */}

          <div className='col1'>
            <label htmlFor='invoiceReceivedDate'>{_lang('Received on', '受取日')}</label>
          </div>
          <div className='invoice-col2 info-tooltip-container'>
            <div>
              <DateInput
                id='invoiceReceivedDate'
                value_local0={invoice.dateisoInvoiceReceived ? local0_from_iso(invoice.dateisoInvoiceReceived) : null}
                disabled={!enableEditing}
                onChange={(date_local0) => {
                  const newDateInvoiceReceived = date_local0 ? iso_from_local0(date_local0) : '';
                  const updateObj: Partial<InvoiceType> = {
                    dateisoInvoiceReceived: newDateInvoiceReceived,
                  };
                  if (action === 'add') {
                    setInvoice((invoice) => { return { ...invoice!, ...updateObj }; });
                  } else {
                    autosaveNewStep(`Change received date to ‘${newDateInvoiceReceived}’`, updateObj, 'u');
                  }
                }}
              />
            </div>

            <InfoToolTip
              tooltipId='addinvoice-datereceived-tooltip'
              tooltipHtml='Date the invoice was received from the supplier'
              tooltipPlace='bottom'
            />

          </div>



          {/* FIELD: PAY BY */}

          <div className='col1'>
            <label htmlFor='dateinputDeadline'>{_lang('Pay by', '支払期限')}</label>
          </div>
          <div className='invoice-col2'>
            <DateInput
              id='dateinputDeadline'
              value_local0={invoice.dateisoDeadline ? local0_from_iso(invoice.dateisoDeadline) : null}
              disabled={!enableEditing}
              onChange={(date_local0) => {
                const newDeadline = date_local0 ? iso_from_local0(date_local0) : '';
                const updateObj: Partial<InvoiceType> = {
                  dateisoDeadline: newDeadline,
                };
                if (action === 'add') {
                  setInvoice((invoice) => { return { ...invoice!, ...updateObj }; });
                } else {
                  autosaveNewStep(`Change deadline to ‘${newDeadline}’`, updateObj, 'u');
                }
              }}
            />
          </div>



          {/* FIELD: MEMO */}

          <div className='col1'>
            <label htmlFor='memorandum'>{_lang('Memo', '備考')}</label>
          </div>
          <div className='invoice-col2'>
            <EditableFieldAddInvoice
              tableid='invoice'
              rowid='main'
              fieldname='memorandum'
              validationType=''
              currentValue={invoice.memorandum}
              isClickableToEdit={enableEditing}
              editedCell={editedCell}
              setEditedCell={setEditedCell}
              callbackCommitChange={(dbvalue: any) => {
                const updateObj: Partial<InvoiceType> = {
                  memorandum: dbvalue,
                };

                if (action === 'add') {
                  setInvoice((invoice) => { return { ...invoice!, ...updateObj }; });
                } else {
                  autosaveNewStep(`Change memo to ‘${dbvalue}’`, updateObj, 'u');
                }
                setEditedCell(null);
              }}
              isTextArea={true}
              textareaRows={4}
            />
          </div>



          {/* BUTTON: SAVE */}

          <div className='col1'>
          </div>
          <div className='invoice-col2'>
            {action === 'add' && (
              <ButtonTW id='submitButton' type='submit' variant='blue' textSize='md'>
                {invoiceId ? 'Save changes' : _lang('Add invoice', '請求書を追加する')}
              </ButtonTW>
            )}
          </div>



          {/* STATUS MESSAGE */}

          <div className='col1'>
          </div>
          <div className={`invoice-col2 alert ${formStatus.className}`}>
            {formStatus.msg}
          </div>

        </div>
      </form>

      {/* <div>payeeObj: {payeeObj?.id}</div> */}



    </div>
  );
}

